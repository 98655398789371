import React from 'react';
import style from './pdf-puck.module.css';
import { faFilePdf, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PDFPuckProps {
  name: string
  linkText: string
  link: string
  date: Date
  sameTab?: boolean
}

/**
 * a puck displaying a pdfs information
 * @param props react properties
 */
export function PDFPuck(props: PDFPuckProps) {
  return (
    <div className={style.fileDisplay}>
      <a className={style.fileIcon} href={props.link} target={props?.sameTab ? '_top' : '_blank'} rel={props?.sameTab ? '' : 'noreferrer'}><FontAwesomeIcon icon={faFile}/></a>
      <div className={style.fileTitle}>{props.name}</div>
      <a className={style.name} href={props.link} target={props?.sameTab ? '_top' : '_blank'} rel={props?.sameTab ? '' : 'noreferrer'}> {props.linkText} </a>
      <div className={style.date}>({props.date.toDateString()})</div>
      <a className={style.pdf} href={props.link} target={props?.sameTab ? '_top' : '_blank'} rel={props?.sameTab ? '' : 'noreferrer'}><FontAwesomeIcon icon={faFilePdf}/> PDF </a>
    </div>
  )
}
