import React from 'react';
import { getParentOrigin } from '../../../util/api';
import { getImageUrl } from '../../../util/util';
import style from './find-my-mla.module.css';
import { type Parliament } from '../../../__generated__/graphql';
interface MLAPuckProps {
	firstname: string | undefined
	lastname: string | undefined
	parliament?: Parliament | undefined
	parliamentString?: string | undefined
	isHonourable?: boolean | undefined
	isCounsel?: boolean | undefined
	image?: Image | undefined | null
	style?: string | undefined
	titleStyle?: string | undefined
	title?: string | undefined
}

interface Image {
	path: string | undefined
	description: string | undefined
}

/**
 * MLA headshot puck -  contains headshot image and MLA name
 * @param props react properties
 * @returns find my mla headshot puck
 */
export function MLAPuck(props: MLAPuckProps) {
	const url = getImageUrl(props.image?.path);
	const fName = props.isHonourable ? 'Hon. ' + props.firstname : props.firstname;
	const lName = props.isCounsel ? props.lastname + ', K.C.' : props.lastname;
	const parliament = props.parliamentString ?? (props?.parliament?.number && props?.parliament?.annotation ? `${props.parliament.number}${props.parliament.annotation}` : '42nd');

	return (
		<div className={`${style.mlaPuck} ${props.style ? props.style : ''} mla`}>
			<a href={`${getParentOrigin()}/members/${parliament}-Parliament/${props.lastname}-${props.firstname}`} target="_parent">
				<img src={url} alt={props.image?.description ?? fName + ' ' + lName}></img>
				<div>
					{fName} {lName} <span className={`${props.titleStyle}`}>{props.title}</span>
				</div>
			</a>
		</div>
	)
}
