import React, { useEffect, useState } from 'react'
import style from './committee-about.module.css';
import { Loader } from '../../common/loading/loading';
import { useLocation } from 'react-router-dom';
import { extractNumbersFromString, getCommitteeParliamentLocation, getCommitteeSessionLocation2, groupBy, sendBreadcrumbToParent, sendTitleToParent } from '../../../util/util';
import { CommitteeNav } from '../../common/committee-nav/committee-nav';
import { CommitteeParagraph } from '../../common/committee-paragraph/committee-paragraph';
import { SideNav } from '../../common/side-nav/side-nav';
import { getAPIBaseUrl, getParentOrigin } from '../../../util/api';
import { get } from '../../../api/api';
import { CommitteeParliamentSelect } from '../../common/committee-parliament-select/committee-parliament-select';
import { CommitteeTranscriptCard } from '../../common/committee-transcript-card/committee-transcript-card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import type { committeeNewsQuery } from '../../../interfaces/committee-news';
import { CommitteeNewsEntry } from '../../common/committee-news-card/committee-news-card';
import { useCommitteeAbout } from '../../../api/committee-about';
import { type Session } from './committee-interfaces';
import { useCurrentParliament } from '../../../api/parliament';

interface CommitteeAboutProps {
  isSession: boolean
}

/**
 * The find Parliamentary Committee Page
 * @param props committee about props
 * @returns React Element for the main Parliamentary Committee Page
 */
export function CommitteeAbout(props: CommitteeAboutProps) {
  const location = useLocation();
  const { currentParliament } = useCurrentParliament();
  let session: string | undefined, abbreviation: string, parliament: string, isCurrentPeriod: boolean;
  if (props.isSession) {
    const locationData = getCommitteeSessionLocation2(location);
    abbreviation = locationData.commId;
    parliament = locationData.parliament;
    session = locationData.session;
    const isCurrentSession = extractNumbersFromString(session) === currentParliament?.sessionsByParliamentId?.nodes?.[0]?.number;
    isCurrentPeriod = extractNumbersFromString(parliament) === currentParliament?.number && isCurrentSession;
  } else {
    const locationData = getCommitteeParliamentLocation(location);
    abbreviation = locationData.commId;
    parliament = locationData.parliament;
    isCurrentPeriod = extractNumbersFromString(parliament) === currentParliament?.number;
  }

  const [committeeNews, setCommitteeNews] = useState<committeeNewsQuery>();
  const { loading, committeeAbout } = useCommitteeAbout(parliament, abbreviation, session);
  const committee = committeeAbout?.committeePeriodAbout?.allCommittees?.nodes?.[0];
  const committeePeriod = committee?.committeePeriodsByCommitteeId?.nodes?.[0];
  const committeeDetails = committeePeriod?.details ?? '';
  const committeeMeetings = committeeAbout?.committeePeriodAbout?.allCommitteeMeetings.nodes ?? [];
  const urlString = `${parliament}parliament-${session ? `${session}session-` : ''}${abbreviation}`;
  const getSessionURL = (parliament: string, abbreviation: string, session?: Session | undefined) => {
    return `parliamentary-business/committees/${parliament}-${session ? `${session.number}${session.annotation}session-` : ''}${abbreviation}`;
  };

  useEffect(() => {
    const loadNews = async () => {
      const newsObj = await get<committeeNewsQuery>(`${getAPIBaseUrl()}/pcms/parliamentary-business/committees-news/session/${committeePeriod?.id}`);
      setCommitteeNews(newsObj);
    }
    void loadNews();
  }, [committeePeriod?.id]);

  if (committee?.name) {
    sendTitleToParent(committee.name);
    sendBreadcrumbToParent(committee.shortName);
  }
  const transcriptArray = committeeAbout?.committeeTranscripts ?? [];
  const hansardFilesGrouped = groupBy(transcriptArray, (ct) => ct?.committeeTranscriptAttributeByFileId?.committeeMeetingId ?? 0);
  return (
    <Loader loading={loading} notFound={!committeeAbout}>
      <h2>{parliament} Parliament{session ? `, ${session} Session` : ''}</h2>
      <div>
        <CommitteeParliamentSelect abbreviation={abbreviation} getSessionURL={getSessionURL} currentSession={session} currentParliament={parliament}/>
        <div className={`${style.committeeAboutContent} ${isCurrentPeriod && committeeMeetings.length ? style.hasSideBar : ''}`}>
          <CommitteeNav parliament={parliament} session={session} abbreviation={abbreviation} urlAppendix={urlString} activeIndex={0} />
          <div className={style.info}>
            {committeeDetails
              ? <CommitteeParagraph commString={committeeDetails} title='Welcome' ></CommitteeParagraph>
              : <div>
                <h2>Welcome</h2>
                <div>{`Information for the ${abbreviation} during the ${parliament} Parliament ${session} Session.`}</div>
              </div>
            }
          </div>
          <div className={style.sideBar}>
            {isCurrentPeriod && committeeMeetings.length ? <SideNav headerText='Transcripts' removedPadding={true}>
              {committeeMeetings.map((meeting, index) => {
                const meetingId = meeting.id;
                const hansardFiles = hansardFilesGrouped.get(meetingId);
                const toDisplay = hansardFiles?.find((file) => {
                  const transcript = file.committeeTranscriptAttributeByFileId;
                  return transcript?.bluesStatusByStatusId?.id !== 4;
                });
                const cardStyle = index % 2 === 0 ? style.greyColor : style.plainColor;
                return (
                  <CommitteeTranscriptCard key={`cm-${meetingId}`} urlString={urlString} parOrSessionId={committeePeriod?.id ?? 0} sideBar={true} meeting={meeting} cardStyle={cardStyle} hansardFile={toDisplay} committeeAbbr={committee?.abbreviation} />
                )
              })}
              <div className={style.navButton}>
                <a href={`${getParentOrigin()}/parliamentary-business/committees/${urlString}/meetings`}
                  target='_parent' >View All <FontAwesomeIcon icon={faChevronRight} /></a>
              </div>
            </SideNav> : <></>}

            {isCurrentPeriod && committeeNews?.allCommitteeNewsEntries?.nodes?.length
            ? <SideNav headerText='Committee News' removedPadding={false} divStyle={style.committeeContentNews} navStyle={style.committeeContentNewsList}>
              {committeeNews?.allCommitteeNewsEntries.nodes.map((news, index) => {
                const cardStyle = index % 2 === 0 ? style.plainColor : style.greyColor;
                return (
                  <CommitteeNewsEntry key={index} news={news} cardStyle={cardStyle} />
                )
              })}
              </SideNav>
            : <></>}
          </div>
        </div>
      </div>
    </Loader>
  )
}
