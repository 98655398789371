import React, { useState } from 'react'
import style from '../committee-calendar/committee-calendar.module.css';
import { Loader } from '../../common/loading/loading';
import { TranscriptPagination } from '../committee-calendar/transcript-pagination';
import { CommitteeList } from './committee-list';
import { useCommitteeTranscripts } from '../../../api/committee-transcripts';
import { CursorPagination } from '../../common/cursor-pagination/cursor-pagination';

/**
 * The find Parliamentary Committee Transcripts and Audios Page
 * @returns React Element for list of Parliamentary Committee Transcripts andn Audios
 */
export function CommitteeTranscripts() {
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const { committeeTranscripts, loading } = useCommitteeTranscripts(startCursor, endCursor);
  const pageInfo = committeeTranscripts?.pageInfo;
  const pgStCursor = pageInfo?.startCursor;
  const pgEnCursor = pageInfo?.endCursor;
  const hasNextPage = pageInfo?.hasNextPage;
  const hasPreviousPage = pageInfo?.hasPreviousPage;

  const handleCursorChange = (startCursor: string | null, endCursor: string | null) => {
    setStartCursor(startCursor);
    setEndCursor(endCursor);
  };

  return (
    <Loader loading={loading} notFound={!committeeTranscripts}>
      <div className={style.container}>
        <div className={style.commCategory}>
          {committeeTranscripts?.committees !== undefined
            ? <div className={style.committeeSession}>
                <CommitteeList commType={committeeTranscripts.committees}></CommitteeList>
              </div>
            : <></>
          }
        </div>
        <div className={style.transCategory}>
          <CursorPagination
            hasPreviousPage={hasPreviousPage}
            hasNextPage={hasNextPage}
            pgStCursor={pgStCursor}
            pgEnCursor={pgEnCursor}
            handleCursorChange={handleCursorChange}
          />
          {committeeTranscripts !== undefined
            ? <div className={style.transcriptContent}>
                <TranscriptPagination baseUrl='url' data={committeeTranscripts} displayName={true} ></TranscriptPagination>
              </div>
            : <></>
          }
        </div>
      </div>
    </Loader>
  )
}
