import { useEffect, useState } from 'react';
import { get } from './api';
import { getAPIBaseUrl } from '../util/api';

export interface AncillaryFileQuery {
  allParliamentaryFileAttributes: AllParliamentaryFileAttributes
}

export interface AllParliamentaryFileAttributes {
  nodes: AncillaryFile[]
}

export interface AncillaryFile {
  fileName: string
  filePath: string
  published: boolean
  publishTime: null
  date: null
  title: string
  ancillaryAttributesByFileId: AncillaryAttributesByFileID
}

export interface AncillaryAttributesByFileID {
  nodes: AncillaryAttributesByFileIDNode[]
}

export interface AncillaryAttributesByFileIDNode {
  sortOrder: number
  billIndex: boolean
}

/**
 * react hook for getting all the members of parliment
 * @param sessionId id of the session for files
 * @returns useMLAs react hook
 */
export function useAncillaryFiles(sessionId: number) {
  const [ancillaryFiles, setAncillaryFiles] = useState<AncillaryFile[] | undefined>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadItems = async () => {
      const ancillaryFileQuery = await get<AncillaryFileQuery>(`${getAPIBaseUrl()}/pdms/ancillary-files/${sessionId}`);
      const ancillaryFiles = ancillaryFileQuery.allParliamentaryFileAttributes?.nodes ?? [];
      ancillaryFiles.sort((a, b) => {
        const sortA = a.ancillaryAttributesByFileId.nodes?.[0].sortOrder;
        const sortB = b.ancillaryAttributesByFileId.nodes?.[0].sortOrder;
        return sortA - sortB;
      });
      setAncillaryFiles(ancillaryFiles);
      setLoading(false);
    };
    void loadItems();
  }, [sessionId]);

  return { ancillaryFiles, loading };
}
