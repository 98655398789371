import React from 'react';
import sanitizeHtml from 'sanitize-html';
import useWindowLocation from '../../hooks/window-location';
import style from './string-content-render.module.css';
interface StringContentRenderProp {
  content: string
  contentStyle?: string
}

/**
 * checks if a url is absolute
 * @param path string to check
 * @returns boolean for absoluteness
 */
function isPathAbsolute(path: string) {
  return /^((http|https):\/\/(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,})$/ig.test(path);
}

/**
 * Component that renders string content
 * @param props react properties
 */
export function StringContentRender(props: StringContentRenderProp) {
  const windowLocation = useWindowLocation();

  const sanitizedContent = {
    __html: sanitizeHtml(props.content, {
    allowedAttributes: {
      '*': ['style'],
      a: ['href', 'target']
    },
    allowedClasses: {
      span: ['text-*'],
      code: ['language-*']
    },
    parseStyleAttributes: false,
    transformTags: {
      a: function(tagName, attribs) {
        let href = attribs.href;
        if (!isPathAbsolute(attribs.href) && !attribs.href.toLowerCase().startsWith('mailto') && !attribs.href.toLowerCase().startsWith('https://')) {
          href = windowLocation + attribs.href
        }
        return {
          tagName,
          attribs: {
            ...attribs,
            href,
            target: '_top'
          }
        }
      }
    }
  })
  };

  return (
    <div className={`${props.contentStyle ? props.contentStyle : ''} ${style.editorContent}`} dangerouslySetInnerHTML={sanitizedContent} />
  );
}
