import React, { useEffect, useState } from 'react'
import { SessionPuck } from '../../common/session-puck/session-puck';
import style from './committee-parliament-select.module.css';
import { getAPIBaseUrl, getParentOrigin } from '../../../util/api';
import { get } from '../../../api/api';
import { ParliamentPuck } from '../parliament-puck/parliament-puck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { type Parliament, type Session } from '../../pages/committee-about/committee-interfaces';

interface CommitteeParliamentMenuProps {
  abbreviation: string
  getSessionURL: (parliament: string, abbreviation: string, session?: Session | undefined) => string
  currentSession?: string
  currentParliament: string
}

/**
 * The find Parliamentary Committee Page
 * @param props properties
 * @returns React Element for the main Parliamentary Committee Page
 */
export function CommitteeParliamentSelect(props: CommitteeParliamentMenuProps) {
  const [committeeParliamentMenu, setCommitteeParliamentMenu] = useState<Parliament[]>();
  useEffect(() => {
    const loadCommitteeAbout = async () => {
      const committeeAboutQuery = await get<Parliament[]>(`${getAPIBaseUrl()}/pcms/committee/${props.abbreviation}/parliaments`);
      setCommitteeParliamentMenu(committeeAboutQuery.reverse());
    };
    void loadCommitteeAbout();
  }, []);

  const lastParliament = committeeParliamentMenu?.[committeeParliamentMenu?.length - 1];
  const lastSession = lastParliament?.sessionsByParliamentId?.nodes?.[0];
  const getLatestUrl = () => {
    if (lastSession && lastParliament) {
      if (props.getSessionURL) {
        return `${getParentOrigin()}/${props.getSessionURL(`${lastParliament.number}${lastParliament.annotation}`, props.abbreviation, lastSession)}`
      }
    }
    return '';
  }

  const isActiveParliament = () => {
    const parliamentName = `${lastParliament?.number}${lastParliament?.annotation}`;
    const sessionName = `${lastSession?.number}${lastSession?.annotation}`;
    if (props.currentSession) {
      return parliamentName === props.currentParliament && sessionName === props.currentSession
    }
    return parliamentName === props.currentParliament;
  }

  return (
    <div className={style.committeeSession} id="parliament-menu">
      <ul>
        {committeeParliamentMenu?.slice().map((committeeParliament) => {
          return props.currentSession
            ? <SessionPuck key={`cp-${committeeParliament.id}`} abbreviation={props.abbreviation} hideCurrentParliament={isActiveParliament()} parliamentObj={committeeParliament} getSessionURL={props.getSessionURL} currentParliament={props.currentParliament} currentSession={props.currentSession}></SessionPuck>
            : <ParliamentPuck key={`cp-${committeeParliament.id}`} abbreviation={props.abbreviation} hideCurrentParliament={isActiveParliament()} parliament={committeeParliament.number} getParliamentURL={props.getSessionURL} annotation={committeeParliament.annotation} currentParliament={props.currentParliament} />
        })}
        {
          lastParliament && lastSession
            ? <a className={`${style.latestButton} ${isActiveParliament() ? style.active : ''}`} href={getLatestUrl()} target='_parent'>Latest Session <FontAwesomeIcon icon={faCaretRight} /></a>
            : <></>
        }
      </ul>
    </div>
  )
}
