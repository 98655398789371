import React from 'react';
import useResizeObserver from '../../hooks/resize-observer';
import { sendSizeToParent } from '../../../util/util';
interface LoaderProps {
	loading?: boolean
  notFound?: boolean
  children: React.ReactNode
}

/**
 * Component for displaying that something is loading
 * @param props content and loading flag
 * @returns React Loading Element
 */
export function Loader(props: LoaderProps) {
	const ref = useResizeObserver<HTMLDivElement>(() => {
		sendSizeToParent()
	});
	return (
		<div ref={ref}>
			{props.loading
      ? (<h2>Loading...</h2>)
      : props.notFound
      ? (<h2>No results found</h2>)
			: (
				<div id="loader-content">
					{props.children}
				</div>
			)
			}
		</div>
	)
}
