import React from 'react'
import { faPhone, faFax, type IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './contact-information-grid.css';

interface ContactInformationGridProps {
  phone?: string
  fax?: string
  tollFreePhone?: string
}
/**
 * creates a contact information grid with icons
 * @param props contact information
 * @returns contact information grid with icons
 */
export function ContactInformationGrid(props: ContactInformationGridProps) {
  return (
    <div className='contact-info-grid'>
      <ContactInfoItem title='Phone' value={props.phone} icon={faPhone}></ContactInfoItem>
      <ContactInfoItem title='Fax' value={props.fax} icon={faFax}></ContactInfoItem>
      <ContactInfoItem title='Toll Free' value={props.tollFreePhone} icon={faPhone}></ContactInfoItem>
    </div>
  )
}

interface ContactInfoItemProps {
  title: string
  value?: string
  icon?: IconDefinition
}
/**
 * a contact information row
 * @param props contact information item
 */
export function ContactInfoItem(props: ContactInfoItemProps) {
  return (
    <>
      {props.value ? <><span>{props.icon ? <FontAwesomeIcon icon={props.icon}/> : <></>} {props.title}: </span><span>{props.value}</span></> : <></>}
    </>
  )
}
