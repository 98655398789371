import React from 'react';
import { TableList } from '../table-list/table-list';
import { type CommitteeInfoObject } from '../../pages/parliamentary-committee-landing-page/parliamentary-committee-landing-page';
import style from './committee-list-section.module.css';
interface ListProp {
  title: string
  committeeList: CommitteeInfoObject[]
}

/**
 * Component to render a list of committees
 * @param items The list of committees to be displayed and their section title
 */
export function CommitteeListSection(items: ListProp) {
  return (
    <div>
      {items.committeeList.length > 0 ? (
        <div>
          <h2>{items.title}</h2>
          <div className={style.committeeList}>
            <TableList items={items.committeeList} committeeStyle={true} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
