import React, { useEffect, useRef, useState } from 'react';
import { getAPIBaseUrl } from '../../../util/api';
import { sendToParent } from '../../../util/util';

interface HeightMessage extends MessageEvent {
  data: {
    type: string
    data: any
  }
}

/**
 * Dynamically styled Standing Orders document
 */
export function StandingOrders() {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handleMessage = (event: HeightMessage) => {
      const data = event.data.data;
      if (data?.height && typeof data.height === 'number') {
        sendToParent('resize', { height: data.height });
        setHeight(data.height);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <iframe
      ref={iframeRef}
      src={`${getAPIBaseUrl()}/pdms/file/standing_orders/standing_orders.html`}
      title="Standing Orders"
      style={{ height, width: '100vw' }}
    />
  );
}
