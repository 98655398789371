import React from 'react';
import style from '../../pages/committee-calendar/committee-calendar.module.css';
import { DropdownItem } from '../dropdown-list/dropdown-item';
import { type FileAttribute } from '../../pages/committee-about/committee-interfaces';

export interface IndexProp {
  committeeIndexes: FileAttribute[]
}

/**
 * Component to render a list of committee indexes
 * @param items The list of index files to be displayed and their title
 */
export function CommitteeIndexList(items: IndexProp) {
  return (
    <div className={`${style.transcript} ${style.rightDisplayed}`}>
      {items.committeeIndexes.map((committeeIndex) => {
        const indexUrl = `hansard-content${committeeIndex?.filePath}/${committeeIndex?.fileName}`;
        return (
          <DropdownItem title={committeeIndex?.title ?? 'Index'} url={indexUrl} key={committeeIndex?.fileName}></DropdownItem>
        );
      })}
    </div>
  );
}
