import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { getParentOrigin } from '../../../util/api';
import style from './session-index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../common/loading/loading';
import { useAncillaryFiles } from '../../../api/ancillary-files';
import { extractNumbersFromString } from '../../../util/util';
import { useSessionIdByNumber, useCurrentParliament } from '../../../api/parliament';
import { gql } from '../../../__generated__';
import { useQuery } from '@apollo/client';

export const GetMemberCount = gql(`
query getMemberCount($parNumber: Int!) {
  allMemberParliaments(
    condition: {active: true}
    filter: {parliamentByParliamentId: {number: {in: [$parNumber]}}}
  ) {
    totalCount
  }
  allParliaments(
    condition: {active: true, number: $parNumber}
    orderBy: NUMBER_DESC
    first: 1
  ) {
    nodes {
      number
      annotation
      id
      sessionsByParliamentId(
        condition: {active: true}
        orderBy: NUMBER_DESC
        first: 1
      ) {
        nodes {
          number
          id
          annotation
        }
      }
    }
  }
}
`)

/**
 * Overview for a session of parliament
 */
export function SessionIndex() {
  const [searchParams] = useSearchParams();
  const parliament = searchParams.get('parliament') ?? '42nd';
  const session = searchParams.get('session') ?? '4th';

  const parNumber = extractNumbersFromString(parliament);
  const sessNumber = extractNumbersFromString(session);
  const sessionId = useSessionIdByNumber(parNumber, sessNumber);

  const { ancillaryFiles, loading: ancLoading } = useAncillaryFiles(sessionId ?? 0);
  const sessionFiles = ancillaryFiles?.filter((file) => {
    return !file.ancillaryAttributesByFileId.nodes?.[0].billIndex;
  });

  const { currentParliament, loading: parLoading } = useCurrentParliament();

  const { loading, data } = useQuery(GetMemberCount, { variables: { parNumber } });
  const parObj = data?.allParliaments?.nodes?.[0];
  // Display if last session of parliament + parliament not current
  const shouldDisplayMembers = parObj?.number === parNumber && parObj?.sessionsByParliamentId?.nodes?.[0]?.number === sessNumber && parNumber !== currentParliament?.number;
  const memberCount = data?.allMemberParliaments?.totalCount ?? 0;

  const baseUrl = `${getParentOrigin()}/parliamentary-business/overview/${parliament}-parliament/${session}-session`;

  return (
    <Loader loading={loading && ancLoading && parLoading} notFound={!sessionId}>
      <div className={style.sessionOverview}>
        <div className={style.groupLinks}>
          <div><a href={`${baseUrl}/orders-of-the-day`} target='_parent'>Orders of the Day</a></div>
          <div><a href={`${baseUrl}/votes-and-proceedings`} target='_parent'>Votes and Proceedings</a></div>
          <div><a href={`${baseUrl}/bills`} target='_parent'>Bills</a></div>
          <div><a href={`${baseUrl}/debate-transcripts`} target='_parent'>Debates of the Legislative Assembly (Hansard)</a></div>

        </div>
        <div className={style.documentLinks}>
          {sessionFiles?.map((acf) => {
            return (
              <div key={acf.fileName} >
                <a href={`${baseUrl}/file/${acf.fileName}`} target='_parent'>
                  <FontAwesomeIcon icon={faFile} /> {acf.title}
                </a>
              </div>
            );
          })}
          {shouldDisplayMembers && memberCount > 0
          ? <div>
              <a href={`${baseUrl}/members`} target='_top'>
                <FontAwesomeIcon icon={faFile} /> Members of the Legislative Assembly at Dissolution of {parliament} Parliament
              </a>
          </div>
          : <></>}
        </div>
      </div>
    </Loader>
  )
}
