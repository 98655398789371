import React from 'react';
import style from '../table-row/table-row.module.css';
import { type CommitteeNodeObj } from '../../pages/previous-committees/previous-committees';
import { DropdownItem } from '../dropdown-list/dropdown-item';

interface CommObjectProp {
  committee: CommitteeNodeObj | undefined
  parliamentLink: string
  parliamentText: string
  committeeType: string
}

/**
 * Table stack for displaying committee name and its table row
 * @param props react properties
 * @returns a patliament block
 */
export function TableParliamentBlock(props: CommObjectProp) {
  const committeeId = props.committee?.abbreviation ?? '';
  const parliamentURL = `${props.parliamentLink}-${committeeId}`;
  const committeePeriods = props.committee?.committeePeriodsByCommitteeId?.nodes ?? [];
  const committeePeriod = committeePeriods.find((cp) => cp.parliamentId);
  const commName = committeePeriod?.shortName ?? props.committee?.shortName ?? props.committee?.name;
  return (
    <div>
      {committeePeriod
        ? <div className={style.parliament}>
            <h3 className={style.commType}>{props.committeeType}</h3>
            <h4 className={`${style.commName} ${style.paliamentSpace}`}>{commName}</h4>
            <DropdownItem title={props.parliamentText} url={parliamentURL}></DropdownItem>
          </div>
        : <></>}
    </div>
  );
}
