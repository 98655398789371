import React from 'react';
import style from '../../pages/committee-about/committee-about.module.css';
import { getParentOrigin } from '../../../util/api';

export interface ParliamentProps {
  parliament: number
  annotation: string
  abbreviation: string
  getParliamentURL: (parliament: string, abbreviation: string) => string
  currentParliament: string
  hideCurrentParliament: boolean
}

/**
 * List of nav that displays sessions items
 * @param props react properties
 * @returns find list of sessions
 */
export function ParliamentPuck(props: ParliamentProps) {
  const parliament = `${props.parliament}${props.annotation} Parliament`;
  const parliamentURL = props.getParliamentURL(`${props.parliament}${props.annotation}parliament`, props.abbreviation);
  const isActive = !props.hideCurrentParliament && `${props.parliament}${props.annotation}` === props.currentParliament;
  return (
    <a className={`${style.nav} ${style.parliamentPuck} ${isActive ? style.currentParliament : ''}`} href={`${getParentOrigin()}/${parliamentURL}`} target='_parent'> {parliament} </a>
  )
}
