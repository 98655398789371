import React from 'react';
import { getParentOrigin } from '../../../util/api';
import { CommitteeListSection } from '../../common/committee-list-section/committee-list-section';
import { type CommitteeData, type CommitteeDataByType, type CommitteeInfoObject } from '../committee-about/committee-interfaces';

export interface CommitteeProp {
  commType: CommitteeDataByType
}

/**
 * Committees displayed by types
 * @param commObj react properties
 */
export function CommitteeList(commObj: CommitteeProp) {
  const setCommitteeList = (obj: CommitteeData[]): CommitteeInfoObject[] => {
    const committeeItemList: CommitteeInfoObject[] = [];
    obj.map((comm) => {
      const commAbbreviation = comm.committeeByCommitteeId.abbreviation;
      const committeeURL = `${getParentOrigin()}/parliamentary-business/committees/committee-meetings/${commAbbreviation}`
      return committeeItemList.push({ text: `${comm.name}`, url: committeeURL });
    });
    return committeeItemList;
  };

  let standingCommitteeList: CommitteeInfoObject[] = [];
  let specialCommitteeList: CommitteeInfoObject[] = [];
  let statutoryCommitteeList: CommitteeInfoObject[] = [];

  const allCommObj = commObj.commType;
  const standing = allCommObj.standing;
  const special = allCommObj.special;
  const statutory = allCommObj.statutory;

  if (standing) {
    standingCommitteeList = setCommitteeList(standing);
  }

  if (special) {
    specialCommitteeList = setCommitteeList(special);
  }

  if (statutory) {
    statutoryCommitteeList = setCommitteeList(statutory);
  }

  return (
    <div>
      {standing
        ? <CommitteeListSection title="Select Standing Committees" committeeList={standingCommitteeList} />
        : <></>}
      {special
        ? <CommitteeListSection title="Special Committees" committeeList={specialCommitteeList} />
        : <></>}
      {statutory
        ? <CommitteeListSection
          title={`${statutory.length > 1 ? 'Statutory Committees' : 'Statutory Committee'}`}
          committeeList={statutoryCommitteeList} />
        : <></>}
    </div>
  );
}
