import { useEffect, useState } from 'react';
import { getParentOrigin } from '../../util/api';
import { getWindowLocation } from '../../util/util';

/**
 * resize observer hook for monitoring when an elements size changes
 */
function useWindowLocation() {
  const [windowLocation, setWindowLocation] = useState(getParentOrigin());
  useEffect(() => {
    window.addEventListener('message', (ev) => {
      setWindowLocation(ev.origin);
    });
    getWindowLocation();
  }, []);
  return windowLocation;
}

export default useWindowLocation;
