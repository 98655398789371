import React from 'react';
import style from '../../pages/committee-calendar/committee-calendar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface PaginationProps {
  hasPreviousPage: boolean | undefined
  hasNextPage: boolean | undefined
  pgStCursor: string | null | undefined
  pgEnCursor: string | null | undefined
  handleCursorChange: (startCursor: string | null, endCursor: string | null, previous?: boolean) => void
}

/**
 * @param props properties for the pagination component
 */
export function CursorPagination (props: PaginationProps) {
  return (
    <div className={style.rightDisplayed}>
      {props.hasPreviousPage && (
        <button className={style.pgButton} onClick={() => { props.handleCursorChange(props.pgStCursor ?? null, null, true); }}>
          <FontAwesomeIcon icon={faChevronLeft} /> Previous
        </button>
      )}
      {' '}
      {props.hasNextPage && (
        <button className={style.pgButton} onClick={() => { props.handleCursorChange(null, props.pgEnCursor ?? null); }}>
          Next <FontAwesomeIcon icon={faChevronRight} />
        </button>
      )}
    </div>
  );
}
