import React from 'react';
import style from './hansard-debate-file.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { type HansardFile } from '../../pages/hansard-debates/hansard-debates';
import { checkForLiveBroadcast, formatBluesDate, onVideoLink } from '../../../util/util';
import { getParentOrigin } from '../../../util/api';
import { HansardTagRenderer } from '../hansard-tag-renderer/hansard-tag-renderer';

interface HansardDebateProps {
  hansardFile: HansardFile
  parliament: string | null
  session: string | null
}

/**
 * Component for displaying that something is loading
 * @param props hansard debate file attributes
 * @returns React Loading Element
 */
export function HansardDebateFile(props: HansardDebateProps) {
  const debateAttributes = props.hansardFile.debateAttributes.nodes?.[0]
  const debateDate = props.hansardFile.debateAttributes.nodes?.[0].date ?? '';
  const committeeA = debateAttributes.committeeALink ? 'Committee A' : '';
  const committeeC = debateAttributes.committeeCLink ? 'Committee C' : '';
  const house = debateAttributes.houseLink ? 'House' : '';
  const date = new Date(debateDate);
  const dateFormat: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  const dateString = date.toLocaleDateString('en-US', dateFormat);
  const bluesModifiedString = formatBluesDate(debateAttributes.bluesModified ?? '');
  const bluesModified = new Date(debateAttributes.bluesModified + 'Z');

  const formatSubscript = (issueNumber: number | null | undefined, volume: number | null) => {
    if (!!issueNumber || !!volume) {
      let str = '(';
      const strPieces = [];

      if (volume && volume !== 0) {
        strPieces.push(`Vol. ${volume}`);
      }
      if (issueNumber && issueNumber !== 0) {
        strPieces.push(`No. ${issueNumber}`);
      }
      str += strPieces.join(', ');
      str += ')';
      return <span className={style.subScript}>{str}</span>;
    }
    return null;
  };

  const houseLink = checkForLiveBroadcast(debateAttributes.houseLink);
  const committeeALink = checkForLiveBroadcast(debateAttributes.committeeALink);
  const committeeCLink = checkForLiveBroadcast(debateAttributes.committeeCLink);
  return (
    <div>
      <div className={style.hansardDebateFile}>
        <FontAwesomeIcon icon={faFile} className={style.fileIcon} />
        <div className={style.fileTitle}>
          {dateString}{debateAttributes.timeOfDay?.name ? `, ${debateAttributes.timeOfDay?.name}` : '' } {(!debateAttributes.issueNumber && debateAttributes.debateType?.name) ? <>— {debateAttributes.debateType.name}</> : <></>}
          <HansardTagRenderer live={!!debateAttributes?.live} statusName={debateAttributes.bluesStatus?.name} bluesModified={bluesModified} bluesModifiedString={bluesModifiedString}/>
          {formatSubscript(debateAttributes.issueNumber, debateAttributes.volume)}
        </div>
        <div className={style.debateLinks}>
          <a href={`${getParentOrigin()}/hansard-content${props.hansardFile.filePath}/${props.hansardFile.fileName}`} target='_parent'>HTML</a>
          {debateAttributes.pdfLink ? <a href={`${getParentOrigin()}/hansard-content${debateAttributes.pdfLink}`} target='_parent'>Official Report (PDF)</a> : <></>}
          {props.hansardFile.stringIndex ? <a href={`${getParentOrigin()}/hansard-content${props.hansardFile.stringIndex}`} target='_parent'>Index</a> : <></>}
          {house ? <a href={houseLink} onClick={onVideoLink}>Chamber Video</a> : <></>}
          {debateAttributes.questionPeriodLink ? <a href={debateAttributes.questionPeriodLink} onClick={onVideoLink}>Question Period Video</a> : <></>}
          {committeeA ? <a href={committeeALink} onClick={onVideoLink}>{committeeA} Video</a> : <></>}
          {committeeC ? <a href={committeeCLink} onClick={onVideoLink}>{committeeC} Video</a> : <></>}
        </div>
      </div>
    </div>
  )
}
