import React from 'react';
import style from './hansard-tag.module.css';

interface HansardTagProps {
  children: React.ReactNode
  isLive?: boolean
  hasMargin?: boolean
}

/**
 * Header for the BC Legislature
 * @param props react props
 */
export function HansardTag(props: HansardTagProps) {
  return (
    <span className={`${props.isLive ? style.liveTag : style.bluesTag} ${props.hasMargin ? style.margin : ''}`}>
      {props.children}
    </span>
  )
}
