import React from 'react';
import { TableList } from '../table-list/table-list';
import { getParentOrigin } from '../../../util/api';
import { faBook, faBuilding, faBullhorn, faFileText, faMicrophone, faPencil, faPencilSquare } from '@fortawesome/free-solid-svg-icons';
import { useCommitteeSideNav } from '../../../api/committee-nav';
import { sendBreadcrumbToParent, sendTitleToParent } from '../../../util/util';
import style from './committee-nav.module.css';
import { type SessionInfoObject } from '../../pages/committee-about/committee-interfaces';
interface Breadcrumb {
  name: string
  order?: number
}

export interface SideNav {
  urlAppendix: string
  activeIndex: number
  parliament: string
  session?: string
  abbreviation: string
  breadcrumbs?: Breadcrumb[]
}

/**
 * @param props holds the list of committee Navs
 * @returns find list of items about a committee
 */
export function CommitteeNav(props: SideNav) {
  const sideNavInfo = useCommitteeSideNav(props.parliament, props.abbreviation, props.session)?.committeeNav;
  const sideNavtItemList: SessionInfoObject[] = [];
  const baseUrl = `${getParentOrigin()}/parliamentary-business/committees/${props.urlAppendix}`;
  const commDetail = sideNavInfo?.details;
  const commContact = sideNavInfo?.contactInfo;
  const commReference = sideNavInfo?.termsOfReference;
  const commMedia = sideNavInfo?.media.totalCount;
  const commAdvert = sideNavInfo?.advertisements.totalCount;
  const commReports = sideNavInfo?.reports.totalCount;
  const commMembership = sideNavInfo?.members.totalCount;
  const commMeetings = sideNavInfo?.meetings.totalCount;
  const commFollowUp = sideNavInfo?.followUp;
  const commRelatedLinks = sideNavInfo?.relatedLinks;
  const committeeMeetingDocuments = sideNavInfo?.meetingFiles.totalCount
  const adHoc = sideNavInfo?.adhocDocuments;
  const individualsDocuments = sideNavInfo?.individualsDocuments;
  const organizationsDocuments = sideNavInfo?.organizationsDocuments;
  if (sideNavInfo?.name) {
    sendTitleToParent(sideNavInfo.name);
  }

  if (commDetail) {
    sideNavtItemList.push({
      text: 'About the Committee',
      url: `${baseUrl}`,
      icon: faBuilding,
      active: props.activeIndex === 0
    });
  }

  if (commReference) {
    sideNavtItemList.push({
      text: 'Terms of Reference',
      url: `${baseUrl}/termsofreference`,
      active: props.activeIndex === 1,
      classes: [style.smallIcon]
    });
  }

  if (commMembership) {
    if (commMembership > 0) {
      sideNavtItemList.push({
        text: 'Committee Membership',
        url: `${baseUrl}/members`,
        active: props.activeIndex === 2,
        classes: [style.smallIcon]
      });
    }
  }

  if (commContact) {
    sideNavtItemList.push({
      text: 'Contact Information',
      url: `${baseUrl}/contact`,
      active: props.activeIndex === 3,
      classes: [style.smallIcon]
    });
  }

  if (commMeetings) {
    if (commMeetings > 0) {
      sideNavtItemList.push({
        text: 'Committee Meetings',
        url: `${baseUrl}/meetings`,
        icon: faPencilSquare,
        active: props.activeIndex === 4
      });
    }
  }
  const hasCommitteeDocuments = !!committeeMeetingDocuments || !!adHoc || !!organizationsDocuments || !!individualsDocuments;
  if (hasCommitteeDocuments) {
    sideNavtItemList.push({
      text: 'Committee Documents',
      url: `${baseUrl}/meetingdocuments`,
      icon: faBook,
      active: props.activeIndex === 10
    });
  }

  if (commReports) {
    sideNavtItemList.push({
      text: 'Committee Reports',
      url: `${baseUrl}/reports`,
      icon: faPencil,
      active: props.activeIndex === 5
    });
  }

  if (commMedia) {
    sideNavtItemList.push({
      text: 'Media Releases',
      url: `${baseUrl}/mediareleases`,
      icon: faMicrophone,
      active: props.activeIndex === 6
    });
  }

  if (commAdvert) {
    sideNavtItemList.push({
      text: 'Advertisements',
      url: `${baseUrl}/advertisements`,
      icon: faBullhorn,
      active: props.activeIndex === 7
    });
  }
  if (commFollowUp) {
    sideNavtItemList.push({
      text: 'Follow Up Process',
      url: `${baseUrl}/followup`,
      icon: faFileText,
      active: props.activeIndex === 8
    });
  }
  if (commRelatedLinks) {
    sideNavtItemList.push({
      text: 'Related Links',
      url: `${baseUrl}/relatedlinks`,
      active: props.activeIndex === 9,
      classes: [style.smallIcon]
    });
  }
  if (props.breadcrumbs && sideNavInfo) {
    for (const breadcrumb of props.breadcrumbs) {
      if (breadcrumb.name === 'shortName' && sideNavInfo?.shortName) {
        sendBreadcrumbToParent(sideNavInfo.shortName, breadcrumb.order)
      } else {
        sendBreadcrumbToParent(breadcrumb.name, breadcrumb.order);
      }
    }
  }

  return (
    <div id='committee-side-nav' className={style.committeeNav}>
      <TableList items={sideNavtItemList} addIcon={true}/>
    </div>
  );
}
