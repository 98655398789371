import { useEffect, useState } from 'react';
import { get } from './api';
import { getAPIBaseUrl } from '../util/api';
import { getQueryString } from '../util/util';

export interface EsSearch {
  file: string
  title: string
  content: string
  sort: Array<number | string>
  highlight: {
    content: string[]
  }
}

interface EsSearchResponse {
  searchResults: EsSearch[]
  total: { value: number, relation: string }
}

/**
 * react hook for getting all the members of parliment
 * @param type the parliament to load
 * @param text the session of the parliament to load
 * @param before score for es pagination
 * @param after score for es pagination
 * @param beforeFile filename for es pagination
 * @param afterFile filename for es pagination
 * @param startDate start date for the search range
 * @param endDate end date for the search range
 * @param queryType the type of search to perform
 * @returns useDocumentSearch react hook
 */
export function useDocumentSearch(type: string, text: string, before: string | null, after: string | null, beforeFile: string | null, afterFile: string | null, startDate: string | null, endDate: string | null, queryType: string | null) {
  const [searchQuery, setSearchQuery] = useState<EsSearchResponse | undefined>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadItems = async () => {
      const queryObj: any = {};
      if (before) queryObj.before = before;
      if (after) queryObj.after = after;
      if (startDate) queryObj.startDate = startDate;
      if (endDate) queryObj.endDate = endDate;
      if (beforeFile) queryObj.beforeFile = beforeFile;
      if (afterFile) queryObj.afterFile = afterFile;
      if (queryType) queryObj.searchType = queryType;
      const queryString = getQueryString(queryObj);
      const searchQuery = await get<EsSearchResponse>(`${getAPIBaseUrl()}/es-search/${type}/${text}${queryString}`);
      setSearchQuery(searchQuery);
      setLoading(false);
    };
    void loadItems();
  }, [type, text, before, after, beforeFile, afterFile, startDate, endDate, queryType]);

  return { searchQuery, loading };
}
