import React from 'react';
import { type CommitteeNode, type ParliamentAndSession } from '../../pages/committee-membership/committee-membership';
import { CommitteePuck } from '../../common/committee-puck/committee-puck';

export interface CommitteeCategoryProps {
  commNodes: CommitteeNode[]
  parliament?: ParliamentAndSession
  isStatutory?: boolean
}

/**
 * @param props holds the list of committee membership object
 * @returns list of committee title and its members under a category
 */
export function CommitteeCategory(props: CommitteeCategoryProps) {
  props.commNodes.sort((a, b) => {
    const aName = a.committeeByCommitteeId.name;
    const bName = b.committeeByCommitteeId.name;
    return aName.localeCompare(bName);
  })
  return (
    <div>
      <ul>
        {props?.commNodes.map((commNode, index) => {
          return (
            <CommitteePuck commNode={commNode} parliament={props?.parliament} isStatutory={props.isStatutory} key={index}></CommitteePuck>
          )
        })}
      </ul>
    </div>
  );
}
