import React from 'react';
import style from './debate-bill-card.module.css';
import { type BillFileAttribute } from '../../../api/bills-by-session';
import { type BillTranscriptLink, type HansardDebateWithBill } from '../../pages/hansard-bills/hansard-bills';
import type { Member } from '../../../__generated__/graphql';
import { groupBy } from '../../../util/util';
import { getParentOrigin } from '../../../util/api';
import { type TimeOfDay } from '../../../api/times-of-day';

interface DebateBillCardProps {
  bill: BillFileAttribute | undefined
  debates: HansardDebateWithBill[] | undefined
  member: Member | undefined
  billPrefix?: string
  parliamentString: string
  sessionString: string
  timesOfDay: TimeOfDay[] | undefined
}

/**
 * A styled card that represents a bill's debates progress
 * @param props DebateBillCardProps
 * @returns DebateBillCard component
 */
export function DebateBillCard(props: DebateBillCardProps) {
  const { bill, debates, member, timesOfDay, parliamentString, sessionString } = props;
  const billPrefix = props?.billPrefix ? `${props.billPrefix} ` : '';

  const billTranscriptNodes = debates?.reduce((acc, debate) => {
    const billTranscriptLinks = debate?.billTranscriptLinksByFileId?.nodes
      ?.filter((link) => link.billId === bill?.id) ?? [];
    return [...acc, ...billTranscriptLinks];
  }, [] as BillTranscriptLink[]);
  const transcriptLinksByReading = groupBy(billTranscriptNodes ?? [], (node) => node.readingTypeId);
  const debatesById = groupBy(debates ?? [], (debate) => debate?.id);

  let latestReading = 0;
  readingTypesOrder.forEach((reading) => {
    const hasDebate = isReadingValid(reading, transcriptLinksByReading, bill);
    if (hasDebate) latestReading = reading;
  });
  const latestProgress = latestReading
    ? readingExpandedName(latestReading)
    : 'Awaiting First Reading';

  const billFirstReading = bill?.billAttributesByBillId?.nodes
    ?.find((billAttribute) => billAttribute?.readingTypeId === 1)
    ?.parliamentaryFileAttributeByFileId?.fileName;
  const linkToBill = billFirstReading?.length
    ? `${getParentOrigin()}/parliamentary-business/overview/${parliamentString}-parliament/${sessionString}-session/bills/1st_read/${billFirstReading}`
    : '';

  return (
    <div className={style.debateBillCard}>
      <div className={style.dbcHeader}>
        <h3>Bill No. {billPrefix}{bill?.billNumber ?? 0}</h3>
        <a href={linkToBill} target='_parent'>{bill?.title ?? 'Unnamed Bill'}</a>
      </div>

      <div>
        <span>Member</span>
        <p>{formatBillMember(member, billPrefix)}</p>

        <span>Latest Progress</span>
        <p>{latestProgress}</p>
      </div>

      <div className={style.dbcBody}>
        {readingTypesOrder?.map((reading, index) => {
          const hasDebate = isReadingValid(reading, transcriptLinksByReading, bill);
          const isLast = index === readingTypesOrder.length - 1;

          const readingTranscriptLinks = transcriptLinksByReading.get(reading) ?? [];
          readingTranscriptLinks.sort((a, b) => sortReadingTranscriptLinks(a, b, debatesById));

          const readingFiles = bill?.billAttributesByBillId?.nodes
            ?.filter((billAttribute) => billAttribute?.readingTypeId === reading) ?? [];
          readingFiles.sort((a, b) => {
            const aDate = new Date(a?.parliamentaryFileAttributeByFileId?.date ?? '');
            const bDate = new Date(b?.parliamentaryFileAttributeByFileId?.date ?? '');
            return aDate.getTime() - bDate.getTime();
          });
          return (
            <div key={index}>
              <div
                key={index}
                className={`${style.progressStep} ${hasDebate ? style.completed : ''} ${isLast ? style.last : ''}`}
              >
                <div className={style.progressBullet}></div>
              </div>

              <div className={style.dbcReadingEntry}>
                <span>{readingExpandedName(reading)}</span>
                <>
                  {readingTranscriptLinks.length
                  ? readingTranscriptLinks.map((transcript, index) => {
                    const debate = debatesById.get(transcript?.fileId ?? 0)?.[0];
                    const date = debate?.date ?? debate?.debateTranscriptAttributeByFileId?.date;
                    const timeOfDay = debate?.debateTranscriptAttributeByFileId?.todId ?? 0;
                    const todName = timesOfDay?.find((tod) => tod.id === timeOfDay)?.name ?? '';

                    const link = `${getParentOrigin()}/hansard-content${debate?.filePath ?? ''}/${debate?.fileName ?? ''}${transcript.anchorTag}`;
                    if (!date) return null;
                    return (
                      <a key={`${reading}-${index}`} href={link} target='_parent'>
                        {formatHansardBillDate(date)}
                        {todName.length ? ` - ${todName}` : ''}
                      </a>
                    );
                  })
                : null}
                </>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const formatHansardBillDate = (dateString: string | null | undefined) => {
  if (!dateString?.length) return '';
  const date = new Date(dateString);
  return `${date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })}`;
};

const formatBillMember = (member: Member | null | undefined, prefix: string) => {
  const honourable = !prefix?.length ? 'Hon. ' : '';
  const firstName = member?.firstName ?? '';
  const lastName = member?.lastName ?? '';
  return `${honourable}${firstName} ${lastName}`;
};

// Ids of reading in their order of bill progression
const readingTypesOrder = [
  1, 4, 5, 3
];

const readingExpandedName = (readingId: number) => {
  switch (readingId) {
    case 1: return 'First Reading';
    case 4: return 'Second Reading';
    case 5: return 'Committee';
    case 3: return 'Third Reading';
    default: return '';
  }
};

const readingTypeToProperty: Record<number, keyof BillFileAttribute> = {
  1: 'firstReading',
  4: 'secondReading',
  5: 'committeeReading',
  3: 'thirdReading'
};

const isReadingValid = (
  reading: number,
  transcriptLinksByReading: Map<number, BillTranscriptLink[]>,
  bill: BillFileAttribute | undefined
) => {
  const hasTranscriptLink = transcriptLinksByReading.has(reading);
  const hasBillDate =
    bill?.[readingTypeToProperty[reading]] ??
    bill?.billAttributesByBillId?.nodes?.some(
      (billAttribute) => billAttribute?.readingTypeId === reading
    );
  return hasTranscriptLink && hasBillDate;
};

const sortReadingTranscriptLinks = (
  a: BillTranscriptLink,
  b: BillTranscriptLink,
  debatesById: Map<number, HansardDebateWithBill[]>
) => {
  const debateA = debatesById.get(a?.fileId ?? 0)?.[0];
  const debateB = debatesById.get(b?.fileId ?? 0)?.[0];

  const dateA = new Date(debateA?.date ?? debateA?.debateTranscriptAttributeByFileId?.date ?? '');
  const dateB = new Date(debateB?.date ?? debateB?.debateTranscriptAttributeByFileId?.date ?? '');
  if (dateA.getDate() !== dateB.getDate()) {
    return dateA.getTime() - dateB.getTime();
  }

  const todA = debateA?.debateTranscriptAttributeByFileId?.todId ?? 0;
  const todB = debateB?.debateTranscriptAttributeByFileId?.todId ?? 0;
  if (todA !== todB) {
    return todA - todB;
  }

  const sortOrderA = a?.sortOrder ?? 0;
  const sortOrderB = b?.sortOrder ?? 0;
  return sortOrderA - sortOrderB;
}
