import React from 'react'
import style from '../committee-about/committee-about.module.css';
import { Loader } from '../../common/loading/loading';
import { useLocation } from 'react-router-dom';
import { getCommitteeParliamentLocation2, getCommitteeSessionLocation } from '../../../util/util';
import { CommitteeNav } from '../../common/committee-nav/committee-nav';
import { CommitteeMembers } from '../../common/commitee-members/committee-members';
import { CommitteeParliamentSelect } from '../../common/committee-parliament-select/committee-parliament-select';
import { useCommitteePeriodMembers } from '../../../api/committee-members';
import { type Session } from '../committee-about/committee-interfaces';

interface CommitteePeriodMembersProps {
  isSession: boolean
}

/**
 * The find Parliamentary Committee Page
 * @param props react props
 * @returns React Element for the main Parliamentary Committee Page
 */
export function CommitteePeriodMembers(props: CommitteePeriodMembersProps) {
  const location = useLocation();
  let session: string | undefined, abbreviation: string, parliament: string;
  if (props.isSession) {
    const locationData = getCommitteeSessionLocation(location);
    abbreviation = locationData.commId;
    parliament = locationData.parliament;
    session = locationData.session;
  } else {
    const locationData = getCommitteeParliamentLocation2(location);
    abbreviation = locationData.commId;
    parliament = locationData.parliament;
  }

  const { committeePeriodMembers, loading } = useCommitteePeriodMembers(parliament, abbreviation, session);
  const commMember = committeePeriodMembers?.committeeMembers?.allMemberParliaments?.nodes.length;
  const urlString = `${parliament}parliament-${session ? `${session}session-` : ''}${abbreviation}`;

  const getSessionURL = (parliament: string, abbreviation: string, session?: Session | undefined) => {
    return `parliamentary-business/committees/${parliament}-${session ? `${session.number}${session.annotation}session-` : ''}${abbreviation}`;
  };

  const breadcrumbs = [
    {
      name: 'shortName',
      order: 2
    },
    {
      name: 'Members'
    }
  ];

  return (
    <Loader loading={loading} notFound={!committeePeriodMembers}>
      <h2>{parliament} Parliament{session ? `, ${session} Session` : ''}</h2>
      <div>
        <CommitteeParliamentSelect abbreviation={abbreviation} getSessionURL={getSessionURL} currentSession={session} currentParliament={parliament}/>
        <div className={style.committeeContent}>
          <CommitteeNav parliament={parliament} session={session} abbreviation={abbreviation} urlAppendix={urlString} activeIndex={2} breadcrumbs={breadcrumbs}/>
          <div className={style.info}>
            <CommitteeMembers commMemberObj={committeePeriodMembers} commMemberLength={commMember ?? 0} title='Members' parliament={parliament}></CommitteeMembers>
          </div>
        </div>
      </div>
    </Loader>
  )
}
