import { useQuery } from '@apollo/client';
import { gql } from '../__generated__';
import { type Parliament } from '../__generated__/graphql';

export const GET_CURRENT_PARLIAMENTS = gql(`
	query GetCurrentParliament {
    allParliaments(condition: { active: true }, orderBy: NUMBER_DESC, first: 1) {
      nodes {
        number
        annotation
        id
        sessionsByParliamentId(
          condition: { active: true }
          orderBy: NUMBER_DESC
          first: 1
        ) {
          nodes {
            number
            id
            annotation
          }
        }
      }
    }
  }
`);

export const GET_ALL_PARLIAMENTS = gql(`
	query GetAllParliaments {
    allParliaments(condition: { active: true }, orderBy: NUMBER_DESC) {
      nodes {
        number
        annotation
        id
        endDate
        startDate
        sessionsByParliamentId(
          condition: { active: true }
          orderBy: NUMBER_DESC
        ) {
          nodes {
            number
            id
            annotation
            endDate
            startDate
            parliamentId
          }
        }
      }
    }
  }
`);

/**
 * The current parliament query
 */
export function useCurrentParliament() {
  const { loading, data } = useQuery(GET_CURRENT_PARLIAMENTS);
  if (data?.allParliaments?.nodes) {
    const currentParliament = data?.allParliaments?.nodes?.[0] as Parliament;
    return { loading, currentParliament };
  }
  return { loading, currentParliament: undefined };
}

/**
 * loads all parliaments
 */
export function useAllParliaments() {
  const { data } = useQuery(GET_ALL_PARLIAMENTS);
  if (data?.allParliaments?.nodes) {
    const currentParliament = data?.allParliaments?.nodes ?? [];
    return currentParliament;
  }
  return undefined;
}

/**
 * Get id of a session given parliament and session number
 * @param parNumber number of parliament
 * @param sessNumber number of session
 * @returns id of session or null if not found
 */
export function useSessionIdByNumber(parNumber: number, sessNumber: number) {
  const { data } = useQuery(GET_ALL_PARLIAMENTS);
  const parliaments = data?.allParliaments?.nodes ?? [];
  const par = parliaments.find((par) => par.number === parNumber);
  if (par) {
    const session = par.sessionsByParliamentId.nodes.find((sess) => sess.number === sessNumber);
    return session?.id ?? null;
  }
  return null
}
