import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getParentOrigin } from '../../../util/api';
import { Loader } from '../../common/loading/loading';
import { useDocumentSearch } from '../../../api/document-search';
import { CursorPagination } from '../../common/cursor-pagination/cursor-pagination';
import { TimePeriodSelect } from '../../common/time-period-select/time-period-select';
import { Header } from '../../common';
import style from './document-search.module.css'
import { StringContentRender } from '../../common/string-content-render/string-content-render';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
/**
 * Overview for the bills of a session
 */
export function DocumentSearch() {
  const [searchParams] = useSearchParams();
  const [startScore, setStartScore] = useState<string | null>(null);
  const [endScore, setEndScore] = useState<string | null>(null);
  const [startFile, setStartFile] = useState<string | null>(null);
  const [endFile, setEndFile] = useState<string | null>(null);
  const type = searchParams.get('type') ?? 'bills';
  const text = searchParams.get('text') ?? '';
  const startQuery = searchParams.get('start') ?? null;
  const endQuery = searchParams.get('end') ?? null;
  const [searchText, setSearchText] = useState<string>(text);
  const [searchType, setSearchType] = useState<string>(type);
  const [startDate, setStartDate] = useState<string | null>(startQuery);
  const [endDate, setEndDate] = useState<string | null>(endQuery);
  const [queryType, setQueryType] = useState<string>('phrase');
  const { searchQuery: searchResults, loading } = useDocumentSearch(searchType, searchText, startScore, endScore, startFile, endFile, startDate, endDate, queryType);

  const startSort = searchResults?.searchResults?.[0]?.sort;
  const [firstSort, setFirstSort] = useState<string | number | undefined>();
  const endSort = searchResults?.searchResults?.[searchResults.searchResults.length - 1]?.sort;

  const total = searchResults?.total?.value;
  const [searchInputText, setSearchInputText] = useState(text);
  const handleCursorChange = (startScore: string | null, endScore: string | null, previous?: boolean) => {
    if (previous) {
      const score = startSort?.[0];
      const file = startSort?.[1];
      if (score && file) {
        setStartScore(String(score));
        setStartFile(String(file));
      }
      setEndScore(null);
      setEndFile(null);
    } else {
      const score = endSort?.[0];
      const file = endSort?.[1];
      if (score && file) {
        setEndScore(String(score));
        setEndFile(String(file));
      }
      setStartFile(null);
      setStartScore(null);
    }
  };

  useEffect(() => {
    if (!firstSort) setFirstSort(startSort?.[0]);
  }, [startSort]);

  const submit = () => {
    setSearchText(searchInputText);
  };

  const getHansardLink = (filePath: string) => {
    return `hansard-content${filePath}`;
  }

  const getCommitteeLink = (filePath: string) => {
    return `committee-content${filePath}`;
  }

  const getPDMSLink = (filePath: string) => {
    return `parliamentary-documents${filePath}`;
  }
  let linkFunction = getHansardLink;

  const hansardTypes = {
    'hansard-debate': 'Hansard Debates',
    index: 'Hansard Indexes',
    'hansard-committees': 'Committee Transcripts',
    'committee-index': 'Committee Indexes'
  };

  const pdmsTypes = {
    bills: 'Bills',
    'first-reading': '-- First Reading',
    amended: '-- Amended',
    'third-reading': '-- Third Reading',
    orders: 'Orders of the Day',
    votes: 'Votes and Proceedings'
  };

  const committeeTypes = {
    meetings: 'Committee Meeting Documents',
    reports: 'Committee Reports',
    advertisements: 'Committee Advertisements',
    media: 'Committee Media Releases'
  }

  if (Object.keys(pdmsTypes).includes(searchType)) linkFunction = getPDMSLink;
  if (Object.keys(committeeTypes).includes(searchType)) linkFunction = getCommitteeLink;
  const changeTimePeriod = (startDate: string | null, endDate: string | null) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const changeInput = (event: React.FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setSearchInputText(value);
  }

  const keyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      submit()
    }
  }
  const seenSet = new Set();
  const uniqueResults = searchResults?.searchResults.filter((result) => {
    if (seenSet.has(result.file)) {
      return false;
    }
    seenSet.add(result.file);
    return true;
  })
  return (
    <Loader loading={loading}>
      <div>
        <div>
          <div className={style.searchBoxContainer}>
            <button type="button" className={style.searchIconContainer} onClick={submit}><FontAwesomeIcon icon={faMagnifyingGlass} /><span className="visually-hidden">Search</span></button>
            <input id='document-search-box' className={style.searchInput} value={searchInputText} onChange={changeInput} type="text" name="keys" placeholder="What are you looking for?" onKeyDown={keyDown} />
            <label htmlFor="document-search-box" className="visually-hidden">Search</label>
            <button type="button" className={style.searchButton} onClick={submit}>Search</button>
          </div>
          <div className={style.searchOptions}>
            <label htmlFor='file-type-select'>
              <span className={style.fileTypeLabel}>File Type</span>
              <select id='file-type-select' className={style.fileTypeSelect} onChange={(ev) => { setSearchType(ev.target.value) }} value={searchType}>
                {Object.entries(pdmsTypes).map(([key, val]) => {
                  return (
                    <option key={key} value={key}>{val}</option>
                  );
                })}
                {Object.entries(hansardTypes).map(([key, val]) => {
                  return (
                    <option key={key} value={key}>{val}</option>
                  );
                })}
                {Object.entries(committeeTypes).map(([key, val]) => {
                  return (
                    <option key={key} value={key}>{val}</option>
                  );
                })}
              </select>
            </label>
            <TimePeriodSelect onChange={changeTimePeriod} />
            <span className={style.queryType}>
              Phrase <input className={style.radio} type='radio' value='phrase' checked={queryType === 'phrase'} onChange={(e) => { setQueryType(e.currentTarget.value) }} /> Keyword <input className={style.radio} type='radio' value='fuzzy' checked={queryType === 'fuzzy'} onChange={(e) => { setQueryType(e.currentTarget.value) }} />
              {(total && total > 0) ? <span className={style.resultsFound}>Found {total} Results</span> : <></>}
            </span>
          </div>
        </div>
        <div>
          <CursorPagination
            hasPreviousPage={firstSort !== startSort?.[0]}
            hasNextPage={true}
            pgStCursor={''}
            pgEnCursor={''}
            handleCursorChange={handleCursorChange}
          />
          <div>
            {Array.isArray(uniqueResults) && uniqueResults?.length && uniqueResults?.length > 0
              ? uniqueResults?.map((result) => {
                const filename = result.file.split('/').pop();
                return (
                  <div key={result.file} className={`${style.searchItem} strong-em`}>
                    <a href={`${getParentOrigin()}/${linkFunction(result.file)}`} target='_top'>{result.title ? result.title : filename}</a>
                    <StringContentRender content={result?.highlight?.content?.join('\r\n') ?? ''} />
                  </div>
                )
              })
              : <Header>No Results Found</Header>
            }
          </div>
        </div>
      </div>
    </Loader>
  )
}
