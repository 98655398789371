import React, { useEffect, useState } from 'react';
import { Loader } from '../../common/loading/loading';
import { useSearchParams } from 'react-router-dom';
import { getAPIBaseUrl, getParentOrigin } from '../../../util/api';
import { get } from '../../../api/api';
import { Header } from '../../common';
import { HansardDebateFile } from '../../common/hansard-debate-file/hansard-debate-file';
import { sortIndexFiles, type IndexFile, daysEqual } from '../../../util/util';
import style from './hansard-debates.module.css'
import { SideNav } from '../../common/side-nav/side-nav';
export interface HansardFilesQuery {
  allHansardFileAttributes?: AllHansardFileAttributes
}

export interface AllHansardFileAttributes {
  nodes: HansardFile[]
}

export interface HansardFile {
  debateAttributes: DebateAttributes
  fileName: string
  name: string
  publishTime: string
  published: boolean
  title: string
  notes: string
  stringIndex: string
  lastModified: string
  filePath: string
}

interface IndexFilesQuery {
  allHansardFileAttributes?: AllIndexFileAttributes
}

interface AllIndexFileAttributes {
  nodes: (IndexFile)[]
}

export interface DebateAttributes {
  nodes: DebateAttribute[]
}

export interface DebateAttribute {
  date: string | null
  bluesModified: string | null
  committeeALink: string
  committeeCLink: string
  houseLink: string
  live: boolean
  issueNumber?: null
  pdfLink: string
  questionPeriodLink: string
  redirectLink: string
  sortOrder: number
  timeOfDay: TimeOfDay
  bluesStatus: TimeOfDayOrBluesStatusOrDebateType
  volume: number
  debateType: DebateType
}

export interface TimeOfDayOrBluesStatusOrDebateType {
  name: string
}

export interface TimeOfDay {
  name: 'Morning' | 'Afternoon' | 'Evening' | 'Night' | 'Special'
}

export interface DebateType {
  name: 'House' | 'Committee A' | 'Committee C' | 'Committee of the Whole'
}

export const timeOfDayMap = {
  Morning: 5,
  Afternoon: 4,
  Evening: 3,
  Night: 2,
  Special: 1
}

export const debateTypeSortOrder = {
  House: 1,
  'Committee A': 2,
  'Committee C': 3,
  'Committee of the Whole': 4
}

/**
 * Component for displaying that something is loading
 * @returns React Loading Element
 */
export function HansardDebates() {
  const [searchParams] = useSearchParams();
  const [debates, setDebates] = useState<HansardFilesQuery>({});
  const [indices, setIndices] = useState<IndexFile[]>();
  const [loading, setLoading] = useState(true);

  const parliament = searchParams.get('parliament');
  const session = searchParams.get('session');

  useEffect(() => {
    const loadHansardDocuments = async () => {
      const hansardDebates = await get<HansardFilesQuery>(`${getAPIBaseUrl()}/hdms/debates/${parliament}${session}`);
      setDebates(hansardDebates);
      setLoading(false);
    }
    void loadHansardDocuments();

    const loadIndexdFiles = async () => {
      const IndexFiles = await get<IndexFilesQuery>(`${getAPIBaseUrl()}/hdms/index/${parliament}${session}`);
      const confirmedIndices = sortIndexFiles(IndexFiles.allHansardFileAttributes?.nodes ?? []);
      setIndices(confirmedIndices);
    }
    void loadIndexdFiles();
  }, [parliament, session]);

  debates.allHansardFileAttributes?.nodes.sort((fileA, fileB) => {
    const dateA = new Date(fileA.debateAttributes.nodes?.[0].date ?? '')
    const dateB = new Date(fileB.debateAttributes.nodes?.[0].date ?? '');
    if (!daysEqual(dateA, dateB)) return dateB.getTime() - dateA.getTime();
    const timeOfDayA = timeOfDayMap[fileA.debateAttributes.nodes?.[0].timeOfDay?.name] ?? 0;
    const timeOfDayB = timeOfDayMap[fileB.debateAttributes.nodes?.[0].timeOfDay?.name] ?? 0;
    if (timeOfDayA !== timeOfDayB) return timeOfDayA - timeOfDayB;
    const debateTypeA = debateTypeSortOrder[fileA.debateAttributes.nodes?.[0].debateType?.name] ?? 0;
    const debateTypeB = debateTypeSortOrder[fileB.debateAttributes.nodes?.[0].debateType?.name] ?? 0;
    return debateTypeA - debateTypeB;
  });

  const today = new Date();
  today.setHours(0, 0, 0);

  const validDebates = debates.allHansardFileAttributes?.nodes.filter((hansardFile) => {
    const debateAttributes = hansardFile.debateAttributes.nodes?.[0];
    const debateDate = hansardFile.debateAttributes.nodes?.[0]?.date ?? '';
    const date = new Date(debateDate);
    const validDate = !isNaN(date.getTime());
    return validDate && debateAttributes.bluesStatus?.name !== 'Blues Redirect Set';
  }) ?? [];

  const todayAtHansard = validDebates.filter((hansardFile) => {
    const debateDate = hansardFile.debateAttributes.nodes?.[0]?.date ?? '';
    const date = new Date(debateDate);
    const validDate = !isNaN(date.getTime());
    return validDate && date > today;
  }) ?? [];

  return (
    <Loader loading={loading} notFound={!validDebates?.length}>
      <div className={style.hansardDebatesContainer}>
        <div>
          {todayAtHansard.length > 0
            ? <>
              <Header>Today at Hansard Services</Header>
              <div className={style.debateFileList}>
                {todayAtHansard.map((hansardFile) => {
                  return (
                    <HansardDebateFile key={hansardFile.fileName} hansardFile={hansardFile} parliament={parliament} session={session}/>
                  )
                })}
              </div>
            </>
            : <></>
          }
        </div>
        <div>
          <Header>{parliament} Parliament, {session} Session</Header>
          <span>The digitally signed PDF Hansard transcript is the Official Report of Debates of the B.C. Legislative Assembly. <a href={`${getParentOrigin()}/learn/hansard-services/digitally-signed-pdfs`} target="_parent">Learn more.</a></span>
        </div>
        <div className={style.hansardDebates}>
          <div className={style.debateFileList}>
            {validDebates.map((hansardFile) => {
              return (
                <HansardDebateFile key={hansardFile.fileName} hansardFile={hansardFile} parliament={parliament} session={session}/>
              )
            })}
          </div>
          <div className={style.searchBox}>
            {/* <SearchBox onSubmit={() => { console.log('TO-DO') }}></SearchBox> */}
          </div>
          <div className={style.indexes}>
            <SideNav headerText='Indexes' divStyle={style.noBefore}>
              {indices ? indices.map((file, i) => {
                const indexFile = file.indexFileAttributeByFileId;
                return (
                  <span key={i}>
                    <a href={`${getParentOrigin()}/hansard-content/${file.filePath}/${file.fileName}`} target='_top' className={style.noBefore}>
                      {file.title}
                    </a>{indexFile?.indexSubtitle ? <span className={style.subtitle}>{` - ${indexFile?.indexSubtitle}`}</span> : ''}
                  </span>
                )
              }) : <></>}
              <a href={`${getParentOrigin()}/parliamentary-business/indexes-to-debate-transcripts`} target='_top' className={style.noBefore}>
                Indexes to Debates and Transcripts</a>
            </SideNav>
          </div>
        </div>
      </div>
    </Loader>
  )
}
