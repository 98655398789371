import React from 'react';
import style from './labc-panel.module.css';
interface LabcPanelProps {
	title: string
  children: React.ReactNode
}

/**
 * Component for displaying that something is loading
 * @param props content and loading flag
 * @returns React Loading Element
 */
export function LabcPanel(props: LabcPanelProps) {
	return (
		<div className={style.labcPanel}>
      <h3>{props.title}</h3>
      <div>
        {props.children}
      </div>
		</div>
	)
}
