import React from 'react';
import { getParentOrigin } from '../../../util/api';
import style from './dropdown-list.module.css';

interface DropdownItemProps {
  title: string
  url: string
  hasBaseUrl?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => any
  isActive?: boolean
}

/**
 * Dropdown - item with a title and associated url
 * @param props react properties
 * @returns find list of sessions
 */
export function DropdownItem(props: DropdownItemProps) {
  let linkUrl = '';
  if (props?.hasBaseUrl) {
    linkUrl = props.url;
  } else {
    linkUrl = `${getParentOrigin()}/${props.url}`;
  }
  return (
    <li className={`${style.listItem} ${props.isActive ? style.selected : ''}`}>
      <a href={linkUrl} target='_parent' onClick={(event) => { if (props.onClick) props.onClick(event) }}> {props.title} </a>
    </li>
  )
}
