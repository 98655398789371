import React from 'react';
import { getParentOrigin } from '../../../util/api';
import style from './committee-puck.module.css';
interface MemberProps {
	firstname: string | undefined
	lastname: string | undefined
	titleStyle?: string | undefined
	title?: string | undefined
	isHonourable: boolean
	parliament: string
}

/**
 * @param props react properties
 * @returns committee member puck
 */
export function CommitteeMember(props: MemberProps) {
	return (
		<div className={`${style.memberPuck} `}>
			<a href={`${getParentOrigin()}/members/${props.parliament}-Parliament/${props.lastname}-${props.firstname}`} target="_parent">
				<div>
					{props.isHonourable ? 'Hon. ' : ''}{props.firstname} {props.lastname} <span className={`${props.titleStyle}`}>{props.title}</span>
				</div>
			</a>
		</div>
	)
}
