import { useLazyQuery } from '@apollo/client';
import { gql } from '../__generated__';

export const GET_MLA_NAME = gql(`
	query GetMLAName($postalCode: String!) {
    allPostalCodeLookups(condition: {postalCode: $postalCode}) {
      nodes {
        constituencyByConstituencyId {
          memberByMemberId {
            firstName
            lastName
          }
        }
      }
    }
  }
`);

/**
 * The mla postalcode lookup function
 * loads the bio data of the MLA
 */
export function useMLAPostalCode() {
  const [loadCode, { loading, data, error }] = useLazyQuery(GET_MLA_NAME, { onCompleted: () => {} });
  return { loadCode, loading, data, error };
}
