import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { FileList } from '../../common/file-list/file-list';
import { getAPIBaseUrl, getParentOrigin } from '../../../util/api';
import { get } from '../../../api/api';

export interface ParliamentaryFileQuery {
  allParliamentaryFileAttributes: AllParliamentaryFileAttributes
}

export interface AllParliamentaryFileAttributes {
  nodes: ParliamentaryFile[]
}

export interface ParliamentaryFile {
  fileName: string
  filePath: string
  votesAttributesByFileId: VotesAttributesByFileID
  published: boolean
  publishTime: string
  date: string
  title: null
}

export interface VotesAttributesByFileID {
  nodes: VotesAttributesByFileIDNode[]
}

export interface VotesAttributesByFileIDNode {
  voteNumbers: string
}

/**
 * The Votes and proceedings page
 * @returns React Element for the votes and proceedings
 */
export function VotesAndProceedings() {
  const [searchParams] = useSearchParams();
  const parliament = searchParams.get('parliament') ?? '42nd';
  const session = searchParams.get('session') ?? '4th';

  const [votes, setVotes] = useState<ParliamentaryFile[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadItems = async () => {
      const files = await get<ParliamentaryFileQuery>(`${getAPIBaseUrl()}/pdms/votes-and-proceedings/${parliament}${session}`);
      const votes = files.allParliamentaryFileAttributes.nodes;
      votes.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB.getTime() - dateA.getTime();
      })
      setVotes(votes);
      setLoading(false);
    };

    loadItems().catch(e => {
      console.log(e);
    });
  }, []);

  const getItemLabel = (item: ParliamentaryFile) => {
    if (item.title) {
      return item.title;
    }
    const date = new Date(item.date + 'Z');
    const dateString = date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    });
    return `Votes (${dateString} No. ${item.votesAttributesByFileId.nodes?.[0].voteNumbers})`;
  }

  const getItemLink = (item: ParliamentaryFile) => {
    const filename = item.fileName;
    const link = `${getParentOrigin()}/parliamentary-business/overview/${parliament}-parliament/${session}-session/votes-and-proceedings/${filename}`
    return link;
  }

  const filterItems = (items: ParliamentaryFile[]) => {
    const copy = [...items];
    copy.shift();
    return copy;
  }

  return (
    <FileList<ParliamentaryFile> items={votes} loading={loading} itemNameCallback={getItemLabel} itemLinkCallback={getItemLink} filterItems={filterItems}></FileList>
  )
}
