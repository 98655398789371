import React from 'react';
import puckStyle from './committee-puck.module.css'
import style from '../../pages/committee-about/committee-about.module.css';
import { type CommitteeNode, type ParliamentAndSession } from '../../pages/committee-membership/committee-membership';
import { CommitteeTitle } from './committee-title';
import { getParentOrigin } from '../../../util/api';
import { CommitteeMember } from './committee-member';

export interface CommitteeNodeProps {
  commNode: CommitteeNode
  parliament?: ParliamentAndSession
  isStatutory?: boolean
}

/**
 * @param props holds the committee membership object
 * @returns committee title and the list of members in a committee
 */
export function CommitteePuck(props: CommitteeNodeProps) {
  const chairData = props.commNode?.committeeChair;
  const chairDeputyData = props.commNode?.committeeDeputyChair;
  const convenerData = props.commNode?.committeeConvener;
  const commData = props.commNode.committeeByCommitteeId;
  const commTitle = commData?.name;
  const memberNode = props.commNode.committeeMembers?.allMemberParliaments?.nodes;
  const commMember = memberNode?.length;
  const parliamentNumber = props.parliament?.parliamentNumber;
  const parliamentAnnotation = props.parliament?.parliamentAnnotation;
  const sessionNumber = props.parliament?.sessionNumber;
  const sessionAnnotation = props.parliament?.sessionAnnotation;
  const parliamentStr = `${parliamentNumber}${parliamentAnnotation}parliament`;
  const sessionStr = `${sessionNumber}${sessionAnnotation}session`;
  const abbr = commData?.abbreviation;
  let committeeURL = `${getParentOrigin()}/parliamentary-business/committees/${parliamentStr}-${sessionStr}-${abbr}`
  if (props.isStatutory) {
    committeeURL = `${getParentOrigin()}/parliamentary-business/committees/${parliamentStr}-${abbr}`;
  }
  memberNode?.sort((a, b) => {
    const aName = a.memberByMemberId.lastName;
    const bName = b.memberByMemberId.lastName;
    return aName.localeCompare(bName);
  });
  return (
    <div className={puckStyle.committeePuck}>
      <CommitteeTitle text={commTitle} url={committeeURL}></CommitteeTitle>
      <ul className={style.memberList}>
        {chairData !== undefined && commMember !== 0
          ? <CommitteeMember firstname={chairData?.memberByMemberId.firstName} lastname={chairData?.memberByMemberId.lastName} key={1} title='(Chair)' titleStyle={style.memberTitle} isHonourable={chairData.isHonourable} parliament={`${parliamentNumber}${parliamentAnnotation}`}></CommitteeMember>
          : <></>}
        {chairDeputyData !== undefined && commMember !== 0
          ? <CommitteeMember firstname={chairDeputyData?.memberByMemberId.firstName} lastname={chairDeputyData?.memberByMemberId.lastName} key={2} title='(Deputy Chair)' titleStyle={style.memberTitle} isHonourable={chairDeputyData.isHonourable} parliament={`${parliamentNumber}${parliamentAnnotation}`}></CommitteeMember>
          : <></>}
        {convenerData !== undefined && commMember !== 0
          ? <CommitteeMember firstname={convenerData?.memberByMemberId.firstName} lastname={convenerData?.memberByMemberId.lastName} key={3} title='(Convener)' titleStyle={style.memberTitle} isHonourable={convenerData.isHonourable} parliament={`${parliamentNumber}${parliamentAnnotation}`}></CommitteeMember>
          : <></>}
        {memberNode?.map((member, index) => {
          return (
            <CommitteeMember firstname={member?.memberByMemberId.firstName} lastname={member?.memberByMemberId.lastName} key={index + 3} isHonourable={member.isHonourable} parliament={`${parliamentNumber}${parliamentAnnotation}`}></CommitteeMember>
          )
        })}
      </ul>
    </div>
  );
}
