import React from 'react'
import { Loader } from '../../common/loading/loading';
import { useLocation } from 'react-router-dom';
import { getCommitteeParliamentLocation2, getCommitteeSessionLocation } from '../../../util/util';
import { type Session } from '../committee-about/committee-interfaces';
import style from './committee-meeting-documents.module.css'
import { CommitteeMeetingDocuments } from '../../common/committee-meeting-documents/committee-meeting-documents';
import { CommitteeParliamentSelect } from '../../common/committee-parliament-select/committee-parliament-select';
import { useCommitteePeriodMeetingDocuments } from '../../../api/committee-meeting-documents';
interface CommitteeMeetingDocumentsProps {
  isSession: boolean
}

/**
 * The find Parliamentary Committee Page
 * @param props committee meeting document props
 * @returns React Element for the main Parliamentary Committee Page
 */
export function CommitteePeriodMeetingDocuments(props: CommitteeMeetingDocumentsProps) {
  const location = useLocation();
  let session: string | undefined, abbreviation: string, parliament: string;
  if (props.isSession) {
    const locationData = getCommitteeSessionLocation(location);
    abbreviation = locationData.commId;
    parliament = locationData.parliament;
    session = locationData.session;
  } else {
    const locationData = getCommitteeParliamentLocation2(location);
    abbreviation = locationData.commId;
    parliament = locationData.parliament;
  }
  const { committeePeriodMeetingDocuments: committeeMeetingDocumentQuery, loading } = useCommitteePeriodMeetingDocuments(parliament, abbreviation, session);

  const urlString = `${parliament}parliament-${session ? `${session}session-` : ''}${abbreviation}`;

  const getSessionURL = (parliament: string, abbreviation: string, session?: Session | undefined) => {
    return `parliamentary-business/committees/${parliament}-${session ? `${session.number}${session.annotation}session-` : ''}${abbreviation}`;
  };

  return (
    <Loader loading={loading} notFound={!committeeMeetingDocumentQuery}>
      <h2>{parliament} Parliament{session ? `, ${session} Session` : ''}</h2>
      <div className={style.committeeMeetings}>
        <CommitteeParliamentSelect getSessionURL={getSessionURL} abbreviation={abbreviation} currentSession={session} currentParliament={parliament}/>
        <CommitteeMeetingDocuments committeeMeetings={committeeMeetingDocumentQuery?.meetingDocuments}
          adhocDocuments={committeeMeetingDocumentQuery?.committeePeriodInformation?.adhocDocuments}
          individualsDocuments={committeeMeetingDocumentQuery?.committeePeriodInformation?.individualsDocuments}
          organizationsDocuments={committeeMeetingDocumentQuery?.committeePeriodInformation?.organizationsDocuments}
          navSettings={{ activeIndex: 10, urlAppendix: urlString, parliament, abbreviation, session }} />
      </div>
    </Loader>
  )
}
