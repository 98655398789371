import React, { useState } from 'react'
import { Loader } from '../../common/loading/loading';
import style from './file-list.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import { getPaginationData } from '../../../util/util';

/**
 * The find mla by constituency page
 * @param props react props
 * @param props.items react props
 * @param props.loading react props
 * @param props.itemNameCallback react props
 * @param props.itemLinkCallback react props
 * @param props.filterItems react props
 * @returns React Element for the main Find mla by constituency
 */
export function FileList<T>(props: {
  items: T[]
  loading?: boolean
  itemNameCallback: (item: T) => string
  itemLinkCallback: (item: T) => string
  filterItems?: (items: T[]) => T[]
}) {
  const [itemOffset, setItemOffset] = useState(0);
  const { pageCount, currentItems, handlePageClick } = getPaginationData(props.items, setItemOffset, itemOffset);
  return (
    <Loader loading={props.loading} notFound={props.items.length === 0}>
      <div className={style.fileList}>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          className='pagination'
          previousLabel="< Previous"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          renderOnZeroPageCount={null}
        />
        {currentItems.map((item, index) => {
          const itemName = props.itemNameCallback(item);
          const link = props.itemLinkCallback(item);
          return (<div key={index}>
            <a href={link} target='_parent'>
              <FontAwesomeIcon icon={faFile} /> {itemName}
            </a>
          </div>);
        })}
      </div>
    </Loader>
  )
}
