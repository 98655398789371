import React, { useEffect, useState } from 'react'
import style from '../committee-about/committee-about.module.css';
import { getAPIBaseUrl } from '../../../util/api';
import { Loader } from '../../common/loading/loading';
import { get } from '../../../api/api';
import { CommitteeBlock } from '../../common/committee-block/committee-block';
import { getYearFromDate } from '../../../util/util';

export interface PrevCommObj {
  block?: {
    parliament: SessionNodeObj
    allCommittees: AllPrevCommittees
  }
}

export interface SessionNodeObj {
  number: number
  id?: number
  annotation: string
  endDate?: string | undefined | null
  startDate?: string | undefined | null
}

export interface PeriodCommitteeNode {
  id: number
  shortName: string
  sessionId?: number
  parliamentId?: number
  number?: number
  annotation?: string
}

export interface CommitteeNodeObj {
  id: number
  name: string
  shortName: string
  abbreviation: string
  typeId: number
  committeePeriodsByCommitteeId: {
    nodes: PeriodCommitteeNode[]
  }
}

export interface AllPrevCommittees {
  standing: CommitteeNodeObj[]
  special: CommitteeNodeObj[]
  statutory: CommitteeNodeObj[]
}

export interface SessionTest {
  text: string
  pos: number
}

export interface DataItemTest {
  one: SessionTest[]
}

/**
 * The find Parliamentary Previous Committee Page
 * @returns React Element for list of Parliamentary Previous Committees
 */
export function PreviousCommittee() {
  const [prevCommittees, setPrevCommittees] = useState<PrevCommObj[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPrevCommittees = async () => {
      const prevCommitteesObj = await get<PrevCommObj[]>(`${getAPIBaseUrl()}/pcms/parliamentary-business/committees/previous-committees`);
      setPrevCommittees(prevCommitteesObj);
      setLoading(false);
    };

    void loadPrevCommittees();
  }, []);

  return (
    <Loader loading={loading} notFound={!prevCommittees?.length}>
      <div>
        {prevCommittees
          ? <div>
              {prevCommittees.map((obj, index) => {
                const blockInfo = obj.block;
                const parInfo = blockInfo?.parliament;
                const parlEndDate = parInfo?.endDate ? getYearFromDate(parInfo?.endDate) : 'present';
                const parlStartDate = getYearFromDate(parInfo?.startDate ?? '');
                const parliamentTile = `${parInfo?.number}${parInfo?.annotation} Parliament (${parlStartDate} - ${parlEndDate})`;
                return (
                  <div key={index}>
                    <h2 className={style.previousParliament}>{parliamentTile}</h2>
                    <CommitteeBlock parliament={parInfo} committee={blockInfo?.allCommittees} key={index}></CommitteeBlock>
                  </div>
                )
              })}
            </div>
          : <></>
        }
      </div>
    </Loader>
  )
}
