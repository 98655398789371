import React from 'react';
import style from '../../pages/committee-calendar/committee-calendar.module.css';

interface DateProps {
  dateString: string
  includeTime?: boolean
  prefix?: string
  divStyle?: string
}

/**
 * Component that convert string date format
 * @param props react properties
 */
export function DateTimeConverter(props: DateProps) {
  const convertToDateObject = (dateString: string): Date => {
    return new Date(dateString);
  };

  const formatDate = (date: Date, includeTime?: boolean): string => {
    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: includeTime
    };

    let formattedDate = date.toLocaleDateString('en-US', dateOptions);

    if (includeTime) {
      const formattedTime = date.toLocaleTimeString('en-US', timeOptions).toUpperCase();
      formattedDate = formattedDate.toUpperCase();

      return `${formattedDate}, ${formattedTime}`;
    }

    return formattedDate;
  };

  const convertedDate = formatDate(convertToDateObject(props.dateString), props.includeTime);

  return (
    <div className={`${style.dateDiv} ${props.divStyle ? props.divStyle : ''}`}>
      {props.prefix} {convertedDate}</div>)
    ;
}
