import { useState, useEffect } from 'react';
import { getAPIBaseUrl } from '../util/api';
import { get } from './api';
import { type AllCommitteeFiles } from '../components/pages/committee-about/committee-interfaces';

export const useCommitteeReports = (stCursor: string | null, enCursor: string | null, isSearch: boolean, searchString?: string) => {
  const [committeeReports, setCommitteeReports] = useState<AllCommitteeFiles>();
  let pcmsUrl = isSearch ? `search/reports/${searchString}` : 'pcms/parliamentary-business/committees/committee-reports';
  pcmsUrl += stCursor ? `?before=${stCursor}` : '';
  pcmsUrl += enCursor ? `${stCursor ? '&' : '?'}after=${enCursor}` : '';

  useEffect(() => {
    const loadCommitteeReports = async () => {
      let committeeReportsObj = [] as AllCommitteeFiles;
      if (isSearch && searchString !== '') {
        committeeReportsObj = await get<AllCommitteeFiles>(`${getAPIBaseUrl()}/${pcmsUrl}`);
      } else {
        committeeReportsObj = await get<AllCommitteeFiles>(`${getAPIBaseUrl()}/${pcmsUrl}`);
      }
      setCommitteeReports(committeeReportsObj);
    };
    void loadCommitteeReports();
  }, [stCursor, enCursor, searchString]);
  return committeeReports;
};
