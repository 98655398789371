import React, { useState } from 'react';
import style from './committee-calendar.module.css';
import { DateTimeConverter } from '../../common/date-time-converter/date-time-converter';
import { StringContentRender } from '../../common/string-content-render/string-content-render';
import { TranscriptLinks } from '../../common/transcript-links/transcript-links';
import { type HansardStatuses, type TranscriptAndAudioNode } from '../committee-about/committee-interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { getParentOrigin } from '../../../util/api';
import { type Parliament } from '../../../__generated__/graphql';
import { formatBluesDate, formatTime } from '../../../util/util';
import { HansardTagRenderer } from '../../common/hansard-tag-renderer/hansard-tag-renderer';

export interface CardObj {
  cardObj: TranscriptAndAudioNode
  statusObj: HansardStatuses
  cardStyle: string
  displayName?: boolean
  isForSideBar?: boolean
  baseUrl: string
  parliaments: Parliament[]
}

/**
 * Transcript card for displaying committee metting details and its trabscripts
 * @param props react properties
 */
export function TranscriptCard(props: CardObj) {
  const isSiderBar = props.isForSideBar;
  const [isDetailsCollapsed, setDetailsCollapsed] = useState(isSiderBar);

  const baseObj = props.cardObj;
  const meetingInfoObj = baseObj.meetingInfo;
  const commName = baseObj.name;
  const meetStartDate = baseObj.meetingInfo?.startTime;
  const meetLocation = meetingInfoObj?.location;
  const meetCity = meetingInfoObj?.city;
  const meetDescription = meetingInfoObj?.description;
  const statusObj = props.statusObj;
  const handsardInfoObj = baseObj.hansardData?.committeeTranscriptAttributeByFileId;
  const statusName = getStatusNameById(statusObj, handsardInfoObj?.bluesStatusByStatusId?.id) ?? handsardInfoObj?.bluesStatusByStatusId?.name;
  const issueNumber = handsardInfoObj?.issueNumber;
  const docInfoObj = baseObj.commDoc?.[0];
  const meetingId = baseObj.meetingInfo?.id;
  const committeeAbbr = baseObj.commAbbr;
  const parliamentId = baseObj.parliamentId;
  const sessionId = baseObj.sessionId;
  let parliamentName = '';
  let sessionName = '';
  if (sessionId) {
    const sessions = props.parliaments.flatMap((parliament) => parliament.sessionsByParliamentId.nodes);
    const session = sessions.find((sess) => sess.id === sessionId);
    const parliament = props.parliaments.find((parl) => parl.id === session?.parliamentId);
    parliamentName = `${parliament?.number}${parliament?.annotation}parliament`;
    sessionName = `-${session?.number}${session?.annotation}session`;
  }
  if (parliamentId) {
    const parliament = props.parliaments.find((parl) => parl.id === parliamentId);
    parliamentName = `${parliament?.number}${parliament?.annotation}parliament`;
  }

  const documentParams = `parliamentary-business/committees/${parliamentName}${sessionName}-${committeeAbbr}/meetings/${meetingId}`;
  const docUrl = `${getParentOrigin()}/${documentParams}`;
  const bluesModified = new Date(handsardInfoObj?.bluesModified + 'Z');
  const bluesModifiedString = formatBluesDate(handsardInfoObj?.bluesModified ?? '');
  return (
    <div>
      <div className={`${style.card} ${props.cardStyle}`}>
        {commName && props.displayName
          ? <div className={style.light}>{commName}</div>
          : <></>
        }
        <div className={style.cardHeader}>
          <div className={style.box}>
            {meetStartDate
              ? <>
                <a href={docUrl} target='_parent'>
                  <DateTimeConverter dateString={meetStartDate} divStyle={style.meetTime} />
                </a>
                {new Date() < new Date(meetStartDate)
                ? (<div className={style.details}>
                    <span>{formatTime(meetStartDate)}</span>
                  </div>
                ) : null}
              </>
              : <a href={docUrl} target='_parent'>{<div className={style.meetTime}>TBD</div>}</a>
            }
            {issueNumber && issueNumber !== 0
              ? <span className={style.issueNumber}>{`Issue No. ${issueNumber}`}</span>
              : <></>
            }
          </div>
          {isSiderBar
            ? <button
              className={style.toggleButton}
              onClick={() => { setDetailsCollapsed(!isDetailsCollapsed); }}
            >
              {isDetailsCollapsed ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} />
              )}
            </button>
            : <></>
          }
          <HansardTagRenderer live={!!handsardInfoObj?.live} statusName={statusName} bluesModified={bluesModified} bluesModifiedString={bluesModifiedString} />
        </div>
        <div className={style.details}>{meetLocation}
          {meetCity
            ? <><span>,</span><span className={style.city}> {meetCity}</span></>
            : <></>
          }
        </div>
        <div>
          {!isDetailsCollapsed && (
            <>
              {meetDescription ? (
                <StringContentRender
                  contentStyle={`${style.details} ${style.italicStyle}`}
                  content={meetDescription}
                ></StringContentRender>
              ) : (
                <></>
              )}

              <TranscriptLinks
                hansardData={baseObj?.hansardData}
                commDoc={docInfoObj}
                docUrl={documentParams}
                prefix='Minutes/Transcript:'
              ></TranscriptLinks>

            </>
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * @param stName string parameter that matches the name from the status id
 * @returns refined string
 */
export function getRefinedStatusName(stName?: string): string | undefined {
  if (stName ?? stName === 'Final') {
    return stName === 'Revised Blues' ? 'BLUES UPDATED' : 'BLUES';
  } else {
    return undefined;
  }
}

/**
 * @param data HandardStatus object table that holds the status name and the id
 * @param nodeId the id of the status to look for
 * @returns name of the status based on the id
 */
export function getStatusNameById(data?: HansardStatuses, nodeId?: number | null): string | undefined {
  const node = data?.allHansardStatuses.nodes.find((n) => n.id === nodeId);
  return node ? node.name : undefined;
};
