import React from 'react';
import style from './members-dissolution.module.css';
import { useSearchParams } from 'react-router-dom';
import { gql } from '../../../__generated__';
import { useQuery } from '@apollo/client';
import { Loader } from '../../common/loading/loading';
import { extractNumbersFromString, openLink, sendTitleToParent, sendToParent } from '../../../util/util';
import { MLAPuck } from '../find-my-mla/mla-puck';
import { useCurrentParliament } from '../../../api/parliament';
import { type Parliament } from '../../../__generated__/graphql';

export const GetDissolutionMembers = gql(`
query getDissolutionMembers($parNumber: Int!) {
  allMemberParliaments(
    condition: { active: true }
    filter: { parliamentByParliamentId: { number: { in: [$parNumber] } } }
  ) {
    nodes {
      isHonourable
      isCounsel
      isDoctor
      image: imageBySmallImageId {
        path
        description
      }
      member: memberByMemberId {
        firstName
        lastName
      }
      parliament: parliamentByParliamentId {
        number
        annotation
        startDate
        endDate
      }
    }
    totalCount
  }
}
`)

/**
 * The find members at dissolution page
 * @returns React Element for the main Find mla by constituency
 */
export function MembersDissolution() {
  sendToParent('removeParliamentSelect', {});

  const [searchParams] = useSearchParams();
  const parNumber = extractNumbersFromString(searchParams.get('parliament') ?? '0');
  const { loading, data } = useQuery(GetDissolutionMembers, { variables: { parNumber } });
  const nodes = [...data?.allMemberParliaments?.nodes ?? []];
  nodes.sort((a, b) => {
    const nameA = a.member?.lastName ?? '';
    const nameB = b.member?.lastName ?? '';
    return nameA.localeCompare(nameB);
  });
  const parliament = nodes[0]?.parliament as Parliament;
  const endDate = parliament?.endDate ?? '';
  const { currentParliament, loading: parLoading } = useCurrentParliament();

  const memberCount = data?.allMemberParliaments?.totalCount ?? -1;
  if (memberCount === 0) {
    sendTitleToParent('Page not found');
    return <div>The requested page could not be found.</div>;
  }

  if (currentParliament?.number && currentParliament.number <= parNumber) {
    openLink('/members');
  }

  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
  const parliamentDate = new Date(endDate + 'Z').toLocaleDateString('en-US', options)
  sendTitleToParent(`${searchParams.get('parliament')} Parliament Members at dissolution${endDate ? ` on ${parliamentDate}` : ''}`);
  return (
    <Loader loading={loading && parLoading} notFound={!nodes?.length}>
      <div className={style.dissolutionGrid}>
        {nodes.map((node, index) => {
          const image = node.image;
          const member = node.member;
          return <MLAPuck key={index} firstname={member?.firstName} lastname={member?.lastName} isHonourable={node.isHonourable} isCounsel={node.isCounsel} image={image} style={style.dissolutionPuck} parliament={parliament} />
        })}
      </div>
    </Loader>
  );
}
