import React, { useState } from 'react';
import style from './progress-of-bills.module.css';
import { useSearchParams } from 'react-router-dom';
import { extractNumbersFromString } from '../../../util/util';
import { useAllParliaments, useSessionIdByNumber } from '../../../api/parliament';
import { ProgressOfBillsTable } from './progress-of-bills-table';
import useBillsBySession from '../../../api/bills-by-session';

/**
 * Dynamically generated progress of bills page
 * @returns ProgressOfBills component
 */
export function ProgressOfBills() {
  const [searchParams] = useSearchParams();
  const parliament = searchParams.get('parliament') ?? '42nd';
  const session = searchParams.get('session') ?? '4th';

  const parNumber = extractNumbersFromString(parliament);
  const sessNumber = extractNumbersFromString(session);
  const sessionId = useSessionIdByNumber(parNumber, sessNumber);

  const { bills } = useBillsBySession(sessionId ?? 0);
  const govBills = bills?.filter(bill => bill.billTypeId === 1);
  const memBills = bills?.filter(bill => bill.billTypeId === 2);
  const prBills = bills?.filter(bill => bill.billTypeId === 3);
  const totalCount = bills?.length ?? 0;

  const [selectedBill, setSelectedBill] = useState(0);

  const handleBillTypeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedBill(Number(event.target.value));
  };

  const parliaments = useAllParliaments();
  const sessionObj = parliaments?.find(parl => parl?.number === parNumber)
    ?.sessionsByParliamentId?.nodes?.find(sess => sess.number === sessNumber);
  const startYear = sessionObj?.startDate?.length
    ? new Date(sessionObj?.startDate ?? '')?.getFullYear().toString()
    : '';

  return (
    <div className={style.progerssOfBills}>
      <div className={style.pobDisclaimer}>
        <p>{session} Session, {parliament} Parliament{startYear?.length ? ` (${startYear})` : ''}</p>

        <div>
          <span>The following electronic version is for informational purposes only.</span>
          <span>The printed version remains the official version.</span>
        </div>
      </div>

      <div className={style.pobHeader}>
        {totalCount
        ? <>
          <h1>Progress of Bills</h1>
          <select value={selectedBill} onChange={handleBillTypeSelect} title='Bill Types'>
            <option value={0}>All Bill Types</option>
            <option value={1}>Government Bills</option>
            <option value={2}>Members&apos; Bills</option>
            <option value={3}>Private Bills</option>
          </select>
        </>
        : <h1>No bills have been tabled for this session.</h1>}
      </div>

      {govBills?.length && (selectedBill === 0 || selectedBill === 1)
        ? <div className={style.pobTableContainer}>
          <h2>Government Bills</h2>
          <span>(Listing printed for convenience only)</span>

          <ProgressOfBillsTable
            bills={govBills}
            parliamentString={parliament}
            sessionString={session}
          />
        </div>
        : null}

      {memBills?.length && (selectedBill === 0 || selectedBill === 2)
        ? <div className={style.pobTableContainer}>
          <h2>Members&apos; Bills</h2>
          <span>(Listing printed for convenience only)</span>

          <ProgressOfBillsTable
            bills={memBills}
            parliamentString={parliament}
            sessionString={session}
            type='M'
          />
        </div>
        : null}

      {prBills?.length && (selectedBill === 0 || selectedBill === 3)
        ? <div className={style.pobTableContainer}>
          <h2>Private Bills</h2>
          <span>(Listing printed for convenience only)</span>

          <ProgressOfBillsTable
            bills={prBills}
            parliamentString={parliament}
            sessionString={session}
            type='Pr'
          />
        </div>
        : null}
    </div>
  );
}
