import { useState, useEffect } from 'react';
import { getAPIBaseUrl } from '../util/api';
import { get } from './api';
import { type CommitteeAboutQuery } from '../interfaces/committee-about';
import { getSessionParams } from '../util/util';

export const useCommitteeAbout = (parliament: string, committeeAbbreviation: string, session?: string) => {
  const [committeeAbout, setCommitteeAbout] = useState<CommitteeAboutQuery | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCommitteeAbout = async () => {
      const params = getSessionParams(session);
      const committeeAboutQuery = await get<CommitteeAboutQuery>(`${getAPIBaseUrl()}/pcms/committees/${parliament}/${committeeAbbreviation}/about`, params);
      setCommitteeAbout(committeeAboutQuery);
      setLoading(false);
    };
    void loadCommitteeAbout();
  }, [parliament, committeeAbbreviation, session]);

  return { committeeAbout, loading };
};
