import React from 'react';
import { DropdownItem } from './dropdown-item';
import style from './dropdown-list.module.css';
import { type Session, type SessionsByParliamentId } from '../../pages/committee-about/committee-interfaces';

export interface DropdownListProps {
  sessions?: SessionsByParliamentId
  parliament: string
  active?: boolean
  abbreviation: string
  getSessionURL: (parliament: string, abbreviation: string, session?: Session | undefined) => string
  currentSession?: string
  isCurrentParliament?: boolean
}

/**
 * Side nav - box with a header and displayed items
 * @param props react properties
 * @param props.list list of array holding each list item info
 * @param props.active hold the open and close style of the list item
 * @returns find list of sessions
 */
export function DropdownList(props: DropdownListProps) {
  return (
   <ul data-testid='dropdownList' className={`${style.dropdownMenu} ${props.active ? style.active : style.inactive}`}>
    {props.sessions?.nodes.slice().map((session, index) => {
      const date = new Date(session.startDate ?? '');
      const endDate = new Date(session.endDate ?? '');
      let dateString = '';
      if (!isNaN(date.getTime())) {
        // valid date
        dateString = ` (${date.getFullYear()}`
        if (!isNaN(endDate.getTime())) {
          if (endDate.getFullYear() !== date.getFullYear()) {
            dateString += ` - ${endDate.getFullYear()})`
          } else {
            dateString += ')';
          }
        } else {
          dateString += ' - Present)'
        }
      }
      const sessionTitle = `${session.number}${session.annotation} Session${dateString}`;
      const sessionURL = props.getSessionURL(props.parliament, props.abbreviation, session);
      const isCurrentSession = props.isCurrentParliament && `${session.number}${session.annotation}` === props.currentSession;
      return (
        <DropdownItem title={ sessionTitle } url={ sessionURL } key={index} isActive={isCurrentSession}></DropdownItem>
      )
    })}
   </ul>
  )
}
