import React, { useState } from 'react'
import style from './committee-reports.module.css';
import { Loader } from '../../common/loading/loading';
import { CursorPagination } from '../../common/cursor-pagination/cursor-pagination';
import { useCommitteeReports } from '../../../api/committee-reports';
import { ReportPagination } from './report-pagination';
import { SearchBox } from '../../common/search-box/search-box';

/**
 * The find Parliamentary Committee Transcripts and Audios Page
 * @returns React Element for list of Parliamentary Committee Transcripts andn Audios
 */
export function CommitteeReports() {
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [searchString, setSearchString] = useState<string>('');
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const committeeReportObj = useCommitteeReports(startCursor, endCursor, isSearch, searchString);
  const committeeReports = committeeReportObj?.allCommitteeFileAttributes ?? committeeReportObj?.searchFiles;
  const pageInfo = committeeReports?.pageInfo;
  const pgStCursor = pageInfo?.startCursor;
  const pgEnCursor = pageInfo?.endCursor;
  const hasNextPage = pageInfo?.hasNextPage;
  const hasPreviousPage = pageInfo?.hasPreviousPage;

  const handleCursorChange = (startCursor: string | null, endCursor: string | null) => {
    setStartCursor(startCursor);
    setEndCursor(endCursor);
  };

  const handleSearchSubmit = (searchString: string) => {
    setSearchString(searchString);
    setStartCursor(null);
    setEndCursor(null);
    searchString !== '' ? setIsSearch(true) : setIsSearch(false);
  };

  return (
      <div className={style.container}>
        <div className={style.searchCategory}>
          <h3>Search Committee Reports</h3>
          <SearchBox onSubmit={handleSearchSubmit} placeholder='Search...'></SearchBox>
        </div>
        <Loader loading={committeeReports === undefined}>
        <div className={style.reportCategory}>
          <CursorPagination
            hasPreviousPage={hasPreviousPage}
            hasNextPage={hasNextPage}
            pgStCursor={pgStCursor}
            pgEnCursor={pgEnCursor}
            handleCursorChange={handleCursorChange}
          />
          {committeeReports !== undefined
            ? <div className={style.reportContent}>
                <ReportPagination reportData={committeeReports} />
              </div>
            : <></>
          }
        </div>
      </Loader>
    </div>
  )
}
