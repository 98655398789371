import React from 'react';
import { Loader } from '../../common/loading/loading';
import useRecentDocuments, { type ParliamentaryDocument, type Debate } from '../../../api/recent-documents';
import { daysEqual, getDateString, groupBy, ordinalSuffix } from '../../../util/util';
import { Header } from '../../common';
import { debateTypeSortOrder, timeOfDayMap } from '../hansard-debates/hansard-debates';
import { getParentOrigin } from '../../../util/api';
import style from './recent-document.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { LabcPanel } from '../../common/labc-panel/labc-panel';

/**
 * sorts hansard debates
 * @param fileA hansard debate
 * @param fileB hansard debate
 * @returns number for sort order
 */
function hansardDebateSort(fileA: Debate, fileB: Debate) {
  const dateA = new Date(fileA.date ?? '')
  const dateB = new Date(fileB.date ?? '');
  if (!daysEqual(dateA, dateB)) return dateB.getTime() - dateA.getTime();
  const timeOfDayA = timeOfDayMap[fileA.timeOfDay?.name] ?? 0;
  const timeOfDayB = timeOfDayMap[fileB.timeOfDay?.name] ?? 0;
  if (timeOfDayA !== timeOfDayB) return timeOfDayA - timeOfDayB;
  const debateTypeA = debateTypeSortOrder[fileA.debateType?.name] ?? 0;
  const debateTypeB = debateTypeSortOrder[fileB.debateType?.name] ?? 0;
  return debateTypeA - debateTypeB;
}

/**
 * gets the title for an order of the day
 * @param order order of the day
 * @returns order of the day title
 */
function getOrderOfTheDayLabel(order: ParliamentaryDocument) {
  if (order.title) return order.title;
  const date = new Date(order.date);
  const dateString = date.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  const orderAttributes = order.ordersAttributesByFileId.nodes?.[0];
  return `Orders (${dateString}, ${orderAttributes.timesOfDayByTodId.name} No. ${orderAttributes.number})`;
}

/**
 * gets the title for a vote and proceeding
 * @param vote vote and proceeding
 * @returns vote and proceeding title
 */
function getVoteAndProccedingLabel(vote: ParliamentaryDocument) {
  if (vote.title) return vote.title;
  const date = new Date(vote.date);
  const dateString = date.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  return `Votes (${dateString} No. ${vote.votesAttributesByFileId.nodes?.[0].voteNumbers})`;
}

/**
 * gets the title for a bill
 * @param bill bill file
 * @returns bill file title
 */
function getBillLabel(bill: ParliamentaryDocument) {
  const parliamentaryBill = bill.billAttributesByFileId.nodes?.[0]?.parliamentaryBillByBillId;
  return `${parliamentaryBill?.title} (Bill ${parliamentaryBill?.billNumber})` ?? `Bill ${parliamentaryBill?.billNumber}`;
}

/**
 * gets the parliament and session from a file path like /ldp/42nd4th/order
 * @param pd parliamentary document
 * @returns the parliament and session as number extracted from the file path
 */
function getParliamentAndSessionFromPath(pd: ParliamentaryDocument) {
  const path = pd.filePath;
  const folders = path.split('/');
  if (folders.length > 2) {
    const sessionFolder = folders[2];
    const sessionString = sessionFolder.slice(sessionFolder.length - 3);
    const parliamentString = sessionFolder.slice(0, sessionFolder.length - 3);
    const parliament = parseInt(parliamentString, 10) ?? 0;
    const session = parseInt(sessionString, 10) ?? 0;
    return { parliament: ordinalSuffix(parliament), session: ordinalSuffix(session) };
  }

  return { parliament: ordinalSuffix(0), session: ordinalSuffix(0) }
}

/**
 * gets the order of the file link
 * @param order order of the day
 * @returns link to the order of the day file
 */
function orderOfTheDayLink(order: ParliamentaryDocument) {
  const { parliament, session } = getParliamentAndSessionFromPath(order)
  const link = `${getParentOrigin()}/parliamentary-business/overview/${parliament}-parliament/${session}-session/orders-of-the-day/${order.fileName}`
  return link;
}

/**
 * gets the votes and proceeding url
 * @param vote vote and proceeding
 * @returns vote and proceeding url
 */
function voteAndProceedingsLink(vote: ParliamentaryDocument) {
  const { parliament, session } = getParliamentAndSessionFromPath(vote)
  const link = `${getParentOrigin()}/parliamentary-business/overview/${parliament}-parliament/${session}-session/votes-and-proceedings/${vote.fileName}`
  return link;
}

/**
 * gets the bill url
 * @param bill bill
 * @returns bill url
 */
function billLink(bill: ParliamentaryDocument) {
  const { parliament, session } = getParliamentAndSessionFromPath(bill);
  const path = bill.filePath.split('/');
  let reading = '1st_read'
  if (path.length > 3) {
    reading = path[3];
  }
  const link = `${getParentOrigin()}/parliamentary-business/overview/${parliament}-parliament/${session}-session/bills/${reading}/${bill.fileName}`
  return link;
}
/**
 * The recent parliamentary documents. Displays votes, order, bills, debates, and committee reports.
 */
export function RecentDocumentsPage() {
  const { recentDocuments, loading } = useRecentDocuments();
  const debates = recentDocuments?.debates ?? [];
  const parliamentaryDocuments = recentDocuments?.parliamentaryDocuments ?? [];
  const committeeDocuments = recentDocuments?.committeeDocuments ?? [];

  debates.sort(hansardDebateSort);
  const debatesGrouped = groupBy(debates, (debate) => getDateString(new Date(debate.date)));
  const pdGrouped = groupBy(parliamentaryDocuments, (pd) => getDateString(new Date(pd.date)));
  const committeeGrouped = groupBy(committeeDocuments, (committee) => getDateString(new Date(committee.date)));
  // get the 5 first dates
  const debateDates = Array.from(debatesGrouped.keys());
  const pdDates = Array.from(pdGrouped.keys());
  const committeeDates = Array.from(committeeGrouped.keys());

  const allDates = [...debateDates, ...pdDates, ...committeeDates];
  const uniqueDateSet = new Set(allDates);
  const uniqueDates = Array.from(uniqueDateSet);
  uniqueDates.sort().reverse();
  const firstFiveDates = uniqueDates.splice(0, 5);

  const getParliamentaryDocumentTitle = (parliamentaryDocument: ParliamentaryDocument) => {
    if (parliamentaryDocument.contentTypeId === 2) {
      return getOrderOfTheDayLabel(parliamentaryDocument);
    } else if (parliamentaryDocument.contentTypeId === 3) {
      return getVoteAndProccedingLabel(parliamentaryDocument);
    } else if (parliamentaryDocument.contentTypeId === 4) {
      return getBillLabel(parliamentaryDocument);
    }
    return '';
  }

  const getParliamentaryDocumentLink = (parliamentaryDocument: ParliamentaryDocument) => {
    if (parliamentaryDocument.contentTypeId === 2) {
      return orderOfTheDayLink(parliamentaryDocument);
    } else if (parliamentaryDocument.contentTypeId === 3) {
      return voteAndProceedingsLink(parliamentaryDocument);
    } else if (parliamentaryDocument.contentTypeId === 4) {
      return billLink(parliamentaryDocument);
    }
    return '';
  }
  return (
    <Loader loading={loading} notFound={!firstFiveDates?.length}>
      <LabcPanel title='Recent Documents'>
        {firstFiveDates.map((date) => {
          const dayDebates = debatesGrouped.get(date) ?? [];
          const dayPD = pdGrouped.get(date) ?? [];
          const dayCommitteeDocuments = committeeGrouped.get(date) ?? [];
          return (
            <div key={date} className={style.recentDocumentDay}>
              <Header>{new Date(date + 'T00:00:00').toDateString()}</Header>
              {dayDebates.map((debate) => {
                return <div key={'debate-' + debate.id} className={style.linkItem}>
                  <a href={`${getParentOrigin()}/hansard-content${debate.hansardFileAttributeByFileId.filePath}/${debate.hansardFileAttributeByFileId.fileName}`} target='_top'>
                    <FontAwesomeIcon icon={faFile} /> {debate.hansardFileAttributeByFileId.title}
                  </a>
                </div>
              })}

              {dayCommitteeDocuments.map((committeeDoc) => {
                const committeeSession = committeeDoc.supportingFileAttributesByFileId.nodes?.[0]?.committeeSessionByCommitteeSessionId;
                const committeeParliament = committeeDoc.supportingFileAttributesByFileId.nodes?.[0]?.committeeParliamentByCommitteeParliamentId
                const committeeName = committeeSession?.name ?? committeeParliament?.name ?? '';
                return <div key={'committee-' + committeeDoc.fileName} className={style.linkItem}>
                  <a href={`${getParentOrigin()}/committee-content${committeeDoc.filePath}/${committeeDoc.fileName}`} target='_top'><FontAwesomeIcon icon={faFile} /> {committeeDoc.title}</a>
                  <div>{committeeName}</div>
                </div>
              })}

              {dayPD.map((pd) => {
                const pdTitle = getParliamentaryDocumentTitle(pd);
                const link = getParliamentaryDocumentLink(pd);
                return <div key={'pd-' + pd.fileName} className={style.linkItem}>
                  <a href={link} target='_top'>
                    <FontAwesomeIcon icon={faFile} /> {pdTitle}
                  </a>
                </div>
              })}
            </div>
          )
        })}
      </LabcPanel>
    </Loader>
  )
}
