import React from 'react';
import style from './committee-news-card.module.css';
import { getParentOrigin, getAPIBaseUrl } from '../../../util/api';
import { PDFPuck } from '../pdf-puck/pdf-puck';
import type { CommitteeNews } from '../../../interfaces/committee-news';
import { DateTimeConverter } from '../date-time-converter/date-time-converter';

interface NewsEntryProps {
  news: CommitteeNews
  cardStyle: string
}
/**
 * Component that renders a single news entry
 * @param props the news entry object
 */
export function CommitteeNewsEntry (props: NewsEntryProps) {
  const news = props.news;
  const mainUrl = `${getParentOrigin()}/committee-content${news.committeeFileAttributeByFileId.filePath}/${news.committeeFileAttributeByFileId.fileName}`;
  const imageUrl = news.imagePath ? (getAPIBaseUrl() + '/pcms/file' + news.imagePath) : '';

  return (
    <div className={props.cardStyle}>
      <h3>{news.committeeFileAttributeByFileId.title}</h3>
      <DateTimeConverter dateString={news.date} />
      {news?.imagePath
      ? <img src={imageUrl} alt={news.committeeFileAttributeByFileId.title} />
      : <></>}
      <div className={style.committeeContentNewsLinks}>
        <a href={mainUrl} target='_top'>{news.primaryLinkTitle}</a>

        {news.committeeNewsLinksByCommitteeNewsId.nodes?.map((link, index) => {
          const url = `${getParentOrigin()}${link.linkPath}`;
          return (
            <a key={index} href={url} target='_top'>{link.title}</a>
          )
        })}
      </div>
    </div>
  )
}

export interface CommitteeNewsCardProps {
  date: string
  title: string
  cardStyle: string
  filePath: string
  fileName: string
  committeeName: string
}

/**
 * Committee News Card to display Reports, Media and Advertisements
 * @param props react properties
 */
export function CommitteeNewsCard(props: CommitteeNewsCardProps) {
  const { date, title, filePath, fileName, committeeName } = props;
  const link = `${getParentOrigin()}/committee-content${filePath}/${fileName}`;

  return (
    <PDFPuck name={committeeName} linkText={title} link={link} date={new Date(date)} sameTab={true} />
  );
}
