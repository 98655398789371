import { useQuery } from '@apollo/client';
import { gql } from '../__generated__';
export const GET_MLAS = gql(`
	query GetAllMLAs($parliamentId: Int!) {
    allMemberParliaments(condition: {active: true, parliamentId: $parliamentId}) {
      nodes {
        isHonourable
        isCounsel
        aboutMember
        active
        constituencyId
        id
        image: imageBySmallImageId {
          path
          description
        }
        memberByMemberId {
          dietaryRestrictions
          electionYears
          firstName
          govEmail
          lastName
          legislativeEmail
          legislativeOfficeId
          memberTypeId
          middleName
          officePhone
          primaryConstituencyOfficeId
          primaryRoleId
          prefix
        }
      }
    }
  }
`);

interface Members {
  isCounsel: boolean
  isHonourable: boolean
  aboutMember: string | undefined | null
  active: boolean
  constituencyId: number | undefined | null
  id: number
  image: Image
  memberByMemberId: {
    dietaryRestrictions: string | undefined | null
    electionYears: string | undefined | null
    firstName: string
    govEmail: string | undefined | null
    lastName: string
    legislativeEmail: string | undefined | null
    legislativeOfficeId: number | undefined | null
    memberTypeId: number | undefined | null
    middleName: string | undefined | null
    officePhone: string | undefined | null
    primaryConstituencyOfficeId: number | undefined | null
    primaryRoleId: number | undefined | null
    prefix: string | undefined | null
  }
}

interface Image {
  path: string
  description: string
}

/**
 * react hook for getting all the members of parliment
 * @param parliamentId current parliament id
 * @returns useMLAs react hook
 */
export function useMLAs(parliamentId: number) {
	const { loading, data, error } = useQuery(GET_MLAS, { variables: { parliamentId } });
	if (data?.allMemberParliaments?.nodes) {
    const membersList = sortMembers(data.allMemberParliaments.nodes as Members[]);
		return { loading, members: membersList, error }
	}
	return { loading, members: undefined, error }
}

/**
 * @param members list of current parliament members
 */
function sortMembers(members: Members[]) {
  const membersCopy = [...members];
  return membersCopy.sort((a, b) => {
    const lastNameA = a.memberByMemberId.lastName.replace(/'/g, 'a');
    const lastNameB = b.memberByMemberId.lastName.replace(/'/g, 'a');
    return lastNameA.localeCompare(lastNameB);
  });
}
