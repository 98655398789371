import React from 'react'
import { Header } from '../../common';
import { getParentOrigin } from '../../../util/api';
import { Loader } from '../../common/loading/loading';
import style from './executive-council.module.css';
import { useCurrentParliament } from '../../../api/parliament';
import { useExecutiveCouncil } from './executive-council-api';

/**
 * The party standings page
 * @returns React Element for the party standings
 */
export function ExecutiveCouncil() {
	const { currentParliament: parliament, loading: parLoading } = useCurrentParliament();
	const { loading, data } = useExecutiveCouncil(parliament?.id);
	const ministers = [...data?.allMemberParliaments?.nodes ?? []];

	const premierRoleSet = new Set(['premier and president of executive council', 'premier and president of the executive council']);
	const attorneyGeneralRoleSet = new Set(['attorney general']);
	/**
	 * formats the council data to be alphabetical based on last name and have the premier always be first
	 */
	const formatCouncilData = () => {
		ministers?.sort((a, b) => {
			const aLastName = a?.memberByMemberId?.lastName ?? '';
			const bLastName = b?.memberByMemberId?.lastName ?? '';
			return aLastName.localeCompare(bLastName);
		});

		const attorneyGeneralIndex = ministers?.findIndex((member) => {
			return member.memberRolesByMemberParliamentId.nodes.find((role) => attorneyGeneralRoleSet.has(role.roleByRoleId?.title.toLowerCase() ?? ''))
		}) ?? 0;
		if (attorneyGeneralIndex) {
			const attorneyGeneral = ministers?.[attorneyGeneralIndex];
			ministers?.splice(attorneyGeneralIndex, 1)
			if (attorneyGeneral) ministers?.unshift(attorneyGeneral);
		}

		const premierIndex = ministers?.findIndex((member) => {
			return member.memberRolesByMemberParliamentId.nodes.find((role) => premierRoleSet.has(role.roleByRoleId?.title.toLowerCase() ?? ''))
		}) ?? 0;
		if (premierIndex) {
			const premier = ministers?.[premierIndex];
			ministers?.splice(premierIndex, 1)
			if (premier) ministers?.unshift(premier);
		}
	};
	formatCouncilData();

	return (
		<>
			<Loader loading={loading && parLoading} notFound={!ministers?.length}>
				<div className={style.executiveCouncil}>
					<Header>MLA</Header>
					<Header>Responsibility</Header>
					{
						ministers?.map((minister, index) => {
							const member = minister?.memberByMemberId;
							const fName = minister?.isHonourable ? 'Hon. ' + member?.firstName : member?.firstName;
							const lName = minister?.isCounsel ? member?.lastName.toUpperCase() + ', K.C.' : member?.lastName.toUpperCase();
							const roles = minister?.memberRolesByMemberParliamentId?.nodes ?? [];
							const titles = roles.map((role) => role.roleByRoleId?.title ?? '');
							return (
								<React.Fragment key={index}>
									<a href={`${getParentOrigin()}/members/${parliament?.number}${parliament?.annotation}-Parliament/${member?.lastName}-${member?.firstName}`} target="_parent">
										{`${lName}, ${fName}`}
									</a>
									<div>{titles.join(' and ')}</div>
								</React.Fragment>
							);
						})
					}
				</div>
			</Loader>
		</>
	)
}
