import React from 'react'
import { Header } from '../../common';
import { useQuery } from '@apollo/client';
import { gql } from '../../../__generated__';
import style from './party-standings.module.css';
import { Loader } from '../../common/loading/loading';
import { useCurrentParliament } from '../../../api/parliament';

/**
 * graphql query to load the amount of members per party and the total number of constituencies
 */
export const GET_PARTY_STANDINGS = gql(`
query GetPartyStandings($parliamentId: Int!) {
  allParties(condition: { active: true }) {
    nodes {
      members: memberParliamentsByPartyId(
        condition: { parliamentId: $parliamentId, active: true }
      ) {
        totalCount
      }
      abbreviation
      name
      nodeId
    }
  }
  allMemberParliaments(condition: { active: true, parliamentId: $parliamentId }) {
    totalCount
  }
}
`);

/**
 * The party standings page
 * @returns React Element for the party standings
 */
export function PartyStandings() {
  const { currentParliament: parliament, loading: parLoading } = useCurrentParliament();
  const { loading, data } = useQuery(GET_PARTY_STANDINGS, { variables: { parliamentId: parliament?.id ?? 0 } });
  const parties = [...data?.allParties?.nodes ?? []];
  const totalConstituencies = data?.allMemberParliaments?.totalCount ?? 0;
  parties?.sort((a, b) => {
    const countA = a?.members.totalCount ?? 0;
    const countB = b?.members.totalCount ?? 0;
    return countB - countA;
  });
  /**
   * gets the number of vacant seats based on the amount per party and the total
   * @deprecated as of 2024-11-04 due to feedback from the clerk relayed to Gareth
   */
  const getVacantSeats = () => {
    let sum = 0;
    parties?.forEach(party => {
      sum += party?.members.totalCount ?? 0;
    });
    return totalConstituencies - sum;
  }

  return (
    <Loader loading={loading && parLoading} notFound={!parties?.length}>
      <div className={style.partyStandings}>
        <Header>Party</Header>
        <Header>Number of Members</Header>
        {
          parties?.map((party) => {
            return (
              <React.Fragment key={party?.nodeId}>
                <div>{party?.abbreviation}</div>
                <div>{party?.members.totalCount}</div>
              </React.Fragment>
            )
          })
        }
        <h4 className={style.total}>Total</h4>
        <h4 className={style.total}>{totalConstituencies}</h4>
      </div>
    </Loader>
  )
}
