import React from 'react';
import puckStyle from './committee-puck.module.css'
import style from '../table-list/table-list.module.css';

interface CommitteeTitleProp {
  text: string
  url: string
  active?: boolean
  committeeStyle?: boolean
}

/**
 * @param props holds the items for the commiittee title
 * @returns committee title
 */
export function CommitteeTitle(props: CommitteeTitleProp) {
  return (
    <div className={puckStyle.puckTitle}>
      <a className={`${props.active ? style.activeLink : style.inactiveLink}`}
              href={props.url} target='_parent'>{props.text}</a>
    </div>
  );
}
