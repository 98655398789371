import React from 'react';
import { HansardTag } from '../hansard-tag/hansard-tag';

interface HansardTagProps {
  statusName: string | null | undefined
  bluesModified: Date
  bluesModifiedString: string
  live: boolean
}

/**
 * Header for the BC Legislature
 * @param props react props
 */
export function HansardTagRenderer(props: HansardTagProps) {
  const { statusName, bluesModified, bluesModifiedString, live } = props;
  const validBluesDate = !isNaN(bluesModified.getTime());
  const bluesDateString = validBluesDate ? bluesModifiedString : 'UPDATED';
  return (
    <>
      {live ? <HansardTag isLive={true}>LIVE</HansardTag> : <></>}
      {statusName === 'Blues Added' || (statusName === 'Revised Blues' && isNaN(bluesModified.getTime())) ? <HansardTag hasMargin={live}>BLUES</HansardTag> : <></>}
      {statusName === 'Revised Blues' && !isNaN(bluesModified.getTime()) ? <HansardTag hasMargin={live}>BLUES (UPDATED {bluesDateString})</HansardTag> : <></>}
    </>
  )
}
