import React, { useEffect, useState } from 'react'
import style from '../../pages/committee-about/committee-about.module.css';
import { getAPIBaseUrl } from '../../../util/api';
import { Loader } from '../../common/loading/loading';
import { get } from '../../../api/api';
import { CommitteeCategory } from './committee-category';

export interface CommitteeMembershipQuery {
  standing: CommitteeNode[]
  special: CommitteeNode[]
  statutory?: CommitteeNode[]
  currentParliament?: ParliamentAndSession
}

export interface CommitteeNode {
  committeeByCommitteeId: CommitteeInfo
  committeeChair?: CommitteeMemberData
  committeeDeputyChair?: CommitteeMemberData
  committeeConvener?: CommitteeMemberData
  committeeMembers?: AllCommitteeMembersData
}

export interface ParliamentAndSession {
  parliamentNumber: number
  parliamentAnnotation: string
  sessionNumber: number
  sessionAnnotation: string
}

export interface CommitteeMemberData {
  id: number
  isHonourable: boolean
  isCounsel: boolean
  imageBySmallImageId?: {
    description: string
    path: string
  }
  memberByMemberId: {
    firstName: string
    lastName: string
  }
}

export interface AllCommitteeMembersData {
  allMemberParliaments?: {
    nodes: CommitteeMemberData[]
  }
}

export interface CommitteeInfo {
  id: number
  typeId: number
  name: string
  abbreviation: string
}

export interface CommitteeTitleObject {
  text: string
  url: string
}

/**
 * The Parliamentary Committee Membership Page
 * @returns React Element for the Parliamentary Committee Membership Page
 */
export function CommitteeMembership() {
  const [committeeMemberships, setCommitteeMemberships] = useState<CommitteeMembershipQuery>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCommitteeMemberships = async () => {
      const committeeMembershipsObj = await get<CommitteeMembershipQuery>(`${getAPIBaseUrl()}/pcms/parliamentary-business/committees/committee-membership`);
      setCommitteeMemberships(committeeMembershipsObj);
      setLoading(false);
    };

    void loadCommitteeMemberships();
  }, []);

  return (
    <Loader
      loading={loading}
      notFound={!committeeMemberships?.standing && !committeeMemberships?.special && !committeeMemberships?.statutory}
    >
      <div>
        <div className={style.committeeMembershipContent}>
          <div className={style.memberInfo}>
            {committeeMemberships?.standing
              ? <div>
                <h2 className={style.first}>Select Standing Committees</h2>
                <div className={style.committeeList}>
                  <CommitteeCategory commNodes={committeeMemberships.standing} parliament={committeeMemberships.currentParliament}></CommitteeCategory>
                </div>
              </div> : <></>}
            {committeeMemberships?.special
              ? <div>
                <h2>Special Committees</h2>
                <div className={style.committeeList}>
                  <CommitteeCategory commNodes={committeeMemberships.special} parliament={committeeMemberships.currentParliament}></CommitteeCategory>
                </div>
              </div> : <></>}
            {committeeMemberships?.statutory
              ? <div>
                <h2>{`${committeeMemberships?.statutory.length > 1 ? 'Statutory Committees' : 'Statutory Committee'}`}</h2>
                <div className={style.committeeList}>
                  <CommitteeCategory commNodes={committeeMemberships.statutory} parliament={committeeMemberships.currentParliament} isStatutory={true}></CommitteeCategory>
                </div>
              </div> : <></>}
          </div>
        </div>
      </div>
    </Loader>
  )
}
