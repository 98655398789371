import React from 'react';
import style from '../table-row/table-row.module.css';
import { type CommitteeNodeObj } from '../../pages/previous-committees/previous-committees';
import { TableRow } from '../table-row/table-row';

interface CommObjectProp {
  committee: CommitteeNodeObj | undefined
  parliament: string
  parliamentNumber: number | undefined
  annotation: string | undefined
}

/**
 * Table stack for displaying committee name and its table row
 * @param props react properties
 * @returns a session block
 */
export function TableSessionBlock(props: CommObjectProp) {
  const committeeId = props.committee?.abbreviation ?? '';
  const committeeInfo = props.committee;
  const committeeSession = committeeInfo?.committeePeriodsByCommitteeId.nodes ?? [];
  const parliamentText = `${props.parliamentNumber}${props.annotation} Parliament`;
  const commName = committeeSession[0]?.shortName ?? committeeInfo?.shortName ?? committeeInfo?.name;
  return (
    <div>
      {committeeInfo
        ? <div>
            <h4 className={style.commName}>{commName}</h4>
            {committeeSession && committeeSession.length > 0 && committeeId !== ''
              ? <div>
                  <TableRow committee={committeeId} parliament={props.parliament} session={committeeSession} ></TableRow>
                </div>
              : <div className={style.commInfo}>{`The ${committeeInfo.shortName} was not active during the ${parliamentText}`}</div>
            }
          </div>
        : <></>}
    </div>
  );
}
