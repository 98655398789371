import React from 'react';
import style from './tab-panel.module.css';
export interface TabProps {
  children: React.ReactNode
  activeIndex: number
  currentIndex: number
}

/**
 * Header for the BC Legislature
 * @param props react props
 */
export function TabPanel(props: TabProps) {
  return (
    <>
      {props.activeIndex === props.currentIndex ? <div className={style.tabPanel}>{props.children}</div> : <></>}
    </>
  );
}
