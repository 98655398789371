/**
 * the api base url
 * @returns string for the api endpoint
 */
export function getAPIBaseUrl(): string {
  return process.env.REACT_APP_API_ENDPOINT ?? 'http://localhost:5433';
};

/**
 * gets the first wrapping documents origin
 * @returns browser url origin
 */
export function getParentOrigin(): string {
  if (window.location?.ancestorOrigins?.length) {
    return window.location?.ancestorOrigins[0]
  }
  return process.env.REACT_APP_PARENT_URL ?? 'http://localhost:8080';
}
