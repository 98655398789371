import React, { useState, useRef } from 'react';
import style from './committee-transcript-card.module.css';
import { DateTimeConverter } from '../../common/date-time-converter/date-time-converter';
import { StringContentRender } from '../../common/string-content-render/string-content-render';
import { TranscriptLinks } from '../../common/transcript-links/transcript-links';
import { type CommitteeMeeting } from '../../pages/committee-about/committee-interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { getParentOrigin } from '../../../util/api';
import { type CommitteeTranscript } from '../../../interfaces/committee-about';
import { formatBluesDate, formatTime } from '../../../util/util';
import { HansardTagRenderer } from '../hansard-tag-renderer/hansard-tag-renderer';

export interface CommitteeTranscriptCardProps {
  meeting: CommitteeMeeting
  sideBar?: boolean
  cardStyle: string
  hansardFile?: CommitteeTranscript
  committeeAbbr: string | undefined
  parOrSessionId: number
  title?: string
  urlString: string
}

/**
 * Transcript card for displaying committee metting detailcs and its trabscripts
 * @param props react properties
 */
export function CommitteeTranscriptCard(props: CommitteeTranscriptCardProps) {
  const isSiderBar = props.sideBar;
  const [isDetailsCollapsed, setDetailsCollapsed] = useState(!!isSiderBar);
  const expandingRef = useRef<HTMLDivElement>(null);

  const meeting = props.meeting;
  const meetStartDate = meeting.startTime;
  const meetLocation = meeting?.location;
  const city = meeting?.city;
  const meetDescription = meeting?.description;
  const hasMeetingDocuments = meeting.meetingFileAttributesByMeetingId?.totalCount;
  const handsardInfoObj = props.hansardFile?.committeeTranscriptAttributeByFileId;
  const issueNumber = handsardInfoObj?.issueNumber;
  const statusName = handsardInfoObj?.bluesStatusByStatusId?.name;
  const title = props?.title;
  const meetingId = meeting.id;
  const urlTail = `parliamentary-business/committees/${props.urlString}/meetings/${meetingId}`;
  const docUrl = `${getParentOrigin()}/${urlTail}`;
  const bluesModified = new Date(handsardInfoObj?.bluesModified + 'Z');
  const bluesModifiedString = formatBluesDate(handsardInfoObj?.bluesModified ?? '');
  return (
    <div>
      <div className={`${style.card} ${props.cardStyle}`}>
        {title ? <div>{title}</div> : <></>}
        <div className={style.cardHeader}>
          <div className={style.box}>
            {meetStartDate
              ? <>
                <a href={docUrl} target='_parent'>
                  <DateTimeConverter
                    dateString={meetStartDate}
                    divStyle={style.meetTime}
                  />
                </a>
                {new Date() < new Date(meetStartDate)
                ? (<div className={style.details}>
                    <span>{formatTime(meetStartDate)}</span>
                  </div>
                ) : null}
              </>
              : <a href={docUrl} target='_parent'>{<div className={style.meetTime}>TBD</div>}</a>
            }
            {issueNumber && issueNumber !== 0
              ? <span className={style.issueNumber}>{`Issue No. ${issueNumber}`}</span>
              : <></>
            }
          </div>
          {isSiderBar
            ? <button
                className={style.toggleButton}
                onClick={() => { setDetailsCollapsed(!isDetailsCollapsed); }}
              >
                {isDetailsCollapsed ? (
                  <FontAwesomeIcon icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon icon={faChevronUp} />
                )}
              </button>
            : <></>
          }
          <HansardTagRenderer live={!!handsardInfoObj?.live} statusName={statusName} bluesModified={bluesModified} bluesModifiedString={bluesModifiedString}/>
        </div>
        <div className={style.details}>
          {meetLocation}
          {city
            ? <><span>,</span><span className={style.city}> {city}</span></>
            : <></>
          }
        </div>
        <div ref={expandingRef} className={`${style.transcriptExpand}`} style={{ maxHeight: isDetailsCollapsed ? '0px' : isSiderBar ? `${expandingRef?.current?.scrollHeight ?? 500}px` : 'none' }} >
          {meetDescription ? (
            <StringContentRender
              contentStyle={`${style.details} ${style.italicStyle}`}
              content={meetDescription}
            ></StringContentRender>
          ) : (
            <></>
          )}
            <TranscriptLinks
              hansardData={props.hansardFile}
              hasMeetingDocuments={!!hasMeetingDocuments}
              docUrl={urlTail}
              prefix='Minutes/Transcript:'
            ></TranscriptLinks>
        </div>
      </div>
    </div>
  );
}
