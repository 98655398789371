import React from 'react'
import { MLAPuck } from './mla-puck';
import { Button, Header } from '../../common';
import { useMLAs, useMLAPostalCode } from '../../../api';
import { Loader } from '../../common/loading/loading';
import { openLink } from '../../../util/util';
import style from './find-my-mla.module.css';
import { SearchBox } from '../../common/search-box/search-box';
import { useCurrentParliament } from '../../../api/parliament';

/**
 * The find my MLA Page
 * @returns React Element for the main Find my MLA Page
 */
export function FindMyMLA() {
  const { currentParliament, loading: parLoading } = useCurrentParliament();
  const { loading, members } = useMLAs(currentParliament?.id ?? 0);
  const { loadCode: loadPostalCode, data } = useMLAPostalCode();
  const fetchData = (postalCode: string) => {
    const allSpaceRemoved = postalCode.replace(/[\s.]+/g, '');
    const postalCodeCapitalized = allSpaceRemoved.toUpperCase();
    void loadPostalCode({
      variables: { postalCode: postalCodeCapitalized }
    });
  }
  if (data?.allPostalCodeLookups?.nodes) {
    const mla = data?.allPostalCodeLookups?.nodes?.[0];
    if (mla) {
      const lastName = mla?.constituencyByConstituencyId?.memberByMemberId?.lastName;
      const firstName = mla?.constituencyByConstituencyId?.memberByMemberId?.firstName;
      openLink(`/members/${currentParliament?.number}${currentParliament?.annotation}-Parliament/${lastName}-${firstName}`);
    }
  }
  return (
    <Loader loading={loading && parLoading} notFound={!members?.length}>
      <div id="find-my-mla" className={style.findMyMla}>
        <div className={style.mlaHeader}>
          <div className={style.postalCode}>
            <Header>Find MLA by Postal Code</Header>
            <SearchBox onSubmit={fetchData} placeholder='Enter Postal Code'></SearchBox>
          </div>
          <Button onClick={() => { openLink('/members/mla-by-community') }}>
            Find MLA by Community
            </Button>
          <Button onClick={() => { openLink('/members/find-mla-by-constituency') }}>
            Find MLA by Constituency
          </Button>
        </div>
        <div className={style.mlaList}>
          {members?.map((member) => {
            return (
              <MLAPuck firstname={member?.memberByMemberId.firstName} lastname={member?.memberByMemberId.lastName} isHonourable={member?.isHonourable} isCounsel={member?.isCounsel} key={member?.id} image={member?.image} parliament={currentParliament}></MLAPuck>
            )
          })}
        </div>
        <div className={style.mlaFooter}>
          <Button onClick={() => { openLink('/members/executive-council') }}>
            Executive Council
          </Button>
          <Button onClick={() => { openLink('/members/current-party-standings') }}>
            Current Party Standings
          </Button>
        </div>
      </div>
    </Loader>
  )
}
