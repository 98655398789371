import React, { useState } from 'react';
import { useAllParliaments } from '../../../api/parliament';
import style from './time-period-select.module.css';
interface TimePeriodSelectProps {
  defaultStartDate?: string | null
  defaultEndDate?: string | null
  onChange?: (startDate: string | null, endDate: string | null) => any
}

/**
 * Generic search box with magnifying glass
 * @param props react props
 */
export function TimePeriodSelect(props: TimePeriodSelectProps) {
  const [value, setValue] = useState<string | undefined>();
  const parliaments = useAllParliaments();
  const allSessions = parliaments?.flatMap((parliament) => parliament.sessionsByParliamentId.nodes ?? []);
  return (
    <label>
      <span className={style.timePeriodLabel}>Time Period</span>
      <select className={style.timePeriodSelect} value={value} onChange={(ev) => {
        const value = ev.target.value;
        setValue(value);
        const valueSub = value.substring(1);
        const valueNumber = parseInt(valueSub, 10);
        let dataObj;
        if (value.startsWith('p')) {
          const parliament = parliaments?.find((parliament) => parliament.id === valueNumber);
          dataObj = parliament;
        } else if (value.startsWith('s')) {
          const session = allSessions?.find((session) => session.id === valueNumber);
          dataObj = session;
        }

        if (props.onChange) {
          props.onChange(dataObj?.startDate, dataObj?.endDate);
        }
      }}>
        <option key={'p-0'} value={'00'}>All</option>
        {parliaments?.map((parliament) => {
          const sessions = parliament.sessionsByParliamentId.nodes ?? [];
          return (
            <>
              <option key={`p${parliament.id}`} value={`p${parliament.id}`}>{parliament.number}{parliament.annotation} Parliament ({parliament.startDate}{parliament.endDate ? ' - ' + parliament.endDate : ''})</option>
              {sessions.map((session) => {
                return (
                  <option key={`s${session.id}`} value={`s${session.id}`}>
                    -- {session.number}{session.annotation} Session ({session.startDate}{session.endDate ? ' - ' + session.endDate : ''})
                  </option>
                )
              })}
            </>
          )
        })}
      </select>
    </label>
  )
}
