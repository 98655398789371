import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ErrorPage } from './components/pages/error/error';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ParliamentaryCommitteeLandingPage } from './components/pages/parliamentary-committee-landing-page/parliamentary-committee-landing-page';
import { ParliamentaryCommitteeNews } from './components/pages/parliamentary-committee-news/parliamentary-committee-news';
import { PreviousCommittee } from './components/pages/previous-committees/previous-committees';
import { FindMyMLA } from './components/pages/find-my-mla/find-my-mla';
import { MLAByConstituency } from './components/pages/mla-by-constituency/mla-by-constituency';
import { PartyStandings } from './components/pages/party-standings/party-standings';
import { ExecutiveCouncil } from './components/pages/executive-council/executive-council';
import { MLABio } from './components/pages/mla-bio/mla-bio';
import { getAPIBaseUrl } from './util/api';
import { MLAByCommunity } from './components/pages/mla-by-community/mla-by-community';
import { sendSizeToParent } from './util/util';
import { OrdersOfTheDay } from './components/pages/orders-of-the-day/orders-of-the-day';
import { VotesAndProceedings } from './components/pages/votes-and-proceedings/votes-and-proceedings';
import { HansardDebates } from './components/pages/hansard-debates/hansard-debates';
import { SessionIndex } from './components/pages/session-index/session-index';
import { BillIndex } from './components/pages/bill-index/bill-index';
import { BillReading } from './components/pages/bill-reading/bill-reading';
import { ParliamentIndex } from './components/pages/parliament-index/parliament-index';
import { CommitteeMembership } from './components/pages/committee-membership/committee-membership';
import { CommitteeTranscripts } from './components/pages/committee-transcripts/committee-transcripts';
import { OneCommitteeTranscripts } from './components/pages/one-committee-transcript/one-committee-transcript';
import { CommitteeMeetingDetails } from './components/pages/committee-meeting-details/committee-meeting-details';
import { CommitteePeriodMeetingDocuments } from './components/pages/committee-meeting-documents/committee-meeting-documents';
import { FileType } from './api/committee-files';
import { RecentDocumentsPage } from './components/pages/recent-documents/recent-documents';
import { MembersDissolution } from './components/pages/members-dissolution/members-dissolution';
import { RecentDocuments } from './components/common/recent-documents/recent-documents';
import { DocumentSearch } from './components/pages/document-search/document-search';
import { CommitteeReports } from './components/pages/committee-reports/committee-reports';
import { StandingOrders } from './components/pages/standing-orders/standing-orders';
import { CommitteeAbout } from './components/pages/committee-about/committee-about';
import { CommitteeMedia } from './components/pages/committee-media/committee-media';
import { CommitteePeriodMembers } from './components/pages/committee-period-members/committee-period-members';
import { CommitteePeriodParagraph } from './components/pages/committee-period-paragraph/committee-period-paragraph';
import { CommitteePeriodMeetings } from './components/pages/committee-meetings/committee-meetings';
import { ProgressOfBills } from './components/pages/progress-of-bills/progress-of-bills';
import { HansardBills } from './components/pages/hansard-bills/hansard-bills';

const client = new ApolloClient({
  uri: `${getAPIBaseUrl()}/graphql`,
  cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

window.addEventListener('load', () => {
  sendSizeToParent();
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <FindMyMLA/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/document-search',
    element: <DocumentSearch/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:id/followup',
    element: <CommitteePeriodParagraph propertyName='followUp' pageTitle='Follow Up Process' pageIndex={8} isSession={false}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:id/relatedlinks',
    element: <CommitteePeriodParagraph propertyName='relatedLinks' pageTitle='Related Links' pageIndex={9} isSession={false} isTwoColumn/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:session/:id/followup',
    element: <CommitteePeriodParagraph propertyName='followUp' pageTitle='Follow Up Process' pageIndex={8} isSession={true}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:session/:id/relatedlinks',
    element: <CommitteePeriodParagraph propertyName='relatedLinks' pageTitle='Related Links' pageIndex={9} isSession={true} isTwoColumn/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:session/:abbreviation/meetings/:id/',
    element: <CommitteeMeetingDetails/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:abbreviation/meetings/:id/',
    element: <CommitteeMeetingDetails/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:id/reports',
    element: <CommitteeMedia title='Committee Reports' fileType={FileType.Report} isSession={false} />,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:id/advertisements',
    element: <CommitteeMedia title='Advertisements' fileType={FileType.Adverisement} isSession={false}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:id/mediareleases',
    element: <CommitteeMedia title='Media Releases' fileType={FileType.Media} isSession={false}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:session/:id/reports',
    element: <CommitteeMedia title='Committee Reports' fileType={FileType.Report} isSession={true}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:session/:id/advertisements',
    element: <CommitteeMedia title='Advertisements' fileType={FileType.Adverisement} isSession={true}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:session/:id/mediareleases',
    element: <CommitteeMedia title='Media Releases' fileType={FileType.Media} isSession={true}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:id/meetings',
    element: <CommitteePeriodMeetings isSession={false}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:session/:id/meetings',
    element: <CommitteePeriodMeetings isSession={true}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/committees-whats-new',
    element: <ParliamentaryCommitteeNews/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/committee-meetings/:commAbbr',
    element: <OneCommitteeTranscripts/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/committee-meetings',
    element: <CommitteeTranscripts/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/committee-membership',
    element: <CommitteeMembership/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/previous-committees',
    element: <PreviousCommittee/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees',
    element: <ParliamentaryCommitteeLandingPage/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:id',
    element: <CommitteeAbout isSession={false}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:id/members',
    element: <CommitteePeriodMembers isSession={false} />,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:id/contact',
    element: <CommitteePeriodParagraph propertyName='contactInfo' pageTitle='Contact Information' pageIndex={3} isSession={false} isTwoColumn/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:id/termsofreference',
    element: <CommitteePeriodParagraph propertyName='termsOfReference' pageTitle='Terms of Reference' pageIndex={1} isTwoColumn isSession={false}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:session/:id',
    element: <CommitteeAbout isSession={true}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:session/:id/contact',
    element: <CommitteePeriodParagraph propertyName='contactInfo' pageTitle='Contact Information' pageIndex={3} isSession={true} isTwoColumn/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:session/:id/termsofreference',
    element: <CommitteePeriodParagraph propertyName='termsOfReference' pageTitle='Terms of Reference' pageIndex={1} isSession={true} isTwoColumn/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:session/:id/meetingdocuments',
    element: <CommitteePeriodMeetingDocuments isSession={true} />,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:id/meetingdocuments',
    element: <CommitteePeriodMeetingDocuments isSession={false}/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/:parliament/:session/:id/members',
    element: <CommitteePeriodMembers isSession={true} />,
    errorElement: <ErrorPage />
  },
  {
    path: '/Search-Constituency',
    element: <MLAByConstituency/>
  },
  {
    path: '/mla-by-community',
    element: <MLAByCommunity/>
  },
  {
    path: '/Party-Standings',
    element: <PartyStandings/>
  },
  {
    path: '/Executive-Council',
    element: <ExecutiveCouncil/>
  },
  {
    path: '/MLA-Bio',
    element: <MLABio/>
  },
  {
    path: '/orders-of-the-day',
    element: <OrdersOfTheDay/>
  },
  {
    path: '/votes-and-proceedings',
    element: <VotesAndProceedings/>
  },
  {
    path: '/hansard-debates',
    element: <HansardDebates />
  },
  {
    path: '/session',
    element: <SessionIndex />
  },
  {
    path: '/bills',
    element: <BillIndex />
  },
  {
    path: '/bills/reading',
    element: <BillReading />
  },
  {
    path: '/parliament',
    element: <ParliamentIndex />
  },
  {
    path: '/recent-documents',
    element: <RecentDocumentsPage/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/embed/recent-documents',
    element: <RecentDocuments/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/members-dissolution',
    element: <MembersDissolution/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/standing-orders',
    element: <StandingOrders/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/parliamentary-business/committees/committee-reports',
    element: <CommitteeReports/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/progress-of-bills',
    element: <ProgressOfBills/>,
    errorElement: <ErrorPage />
  },
  {
    path: '/hansard-bills',
    element: <HansardBills/>,
    errorElement: <ErrorPage />
  }
]);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
