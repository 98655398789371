import { useState, useEffect } from 'react';
import { getAPIBaseUrl } from '../util/api';
import { get } from './api';
import { getSessionParams } from '../util/util';

export interface CommitteeSideNav {
  shortName: string
  termsOfReference: string
  adhocDocuments: string
  organizationsDocuments: string
  individualsDocuments: string
  details: string
  contactInfo: string
  followUp: string
  relatedLinks: string
  members: Count
  meetings: Count
  media: Count
  advertisements: Count
  reports: Count
  meetingFiles: Count
  name: string
}

export interface Count {
  totalCount: number
}

export const useCommitteeSideNav = (parliament: string, committeeAbbreviation: string, session?: string) => {
  const [committeeNav, setCommitteeNav] = useState<CommitteeSideNav | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCommitteeNav = async () => {
      const params = getSessionParams(session);
      const committeeSessionsQuery = await get<CommitteeSideNav[]>(`${getAPIBaseUrl()}/pcms/committees/${parliament}/${committeeAbbreviation}/nav`, params);
      setCommitteeNav(committeeSessionsQuery?.[0]);
      setLoading(false);
    };
    void loadCommitteeNav();
  }, [parliament, session, committeeAbbreviation]);

  return { committeeNav, loading };
};
