import React, { useEffect, useState } from 'react';
import style from './hansard-bills.module.css';
import { useSearchParams } from 'react-router-dom';
import { extractNumbersFromString, groupBy } from '../../../util/util';
import { useAllParliaments, useSessionIdByNumber } from '../../../api/parliament';
import { get } from '../../../api/api';
import { getAPIBaseUrl, getParentOrigin } from '../../../util/api';
import useBillsBySession from '../../../api/bills-by-session';
import { DebateBillCard } from '../../common/debate-bill-card/debate-bill-card';
import { useQuery } from '@apollo/client';
import { GET_BILLS_MEMBERS } from '../progress-of-bills/progress-of-bills-table';
import type { Member } from '../../../__generated__/graphql';
import useTimesOfDay from '../../../api/times-of-day';

export interface HansardDebateWithBill {
  id: number
  filePath?: string | null
  fileName?: string | null
  published: boolean | null
  publishTime?: string | null
  date?: string | null
  billTranscriptLinksByFileId: {
    nodes: BillTranscriptLink[]
  }
  debateTranscriptAttributeByFileId: {
    todId: number | null
    date: string | null
  } | null
  committeeTranscriptAttributeByFileId: {
    committeeMeetingId: number
  } | null
}

export interface BillTranscriptLink {
  fileId: number
  billId: number
  anchorTag?: string | null
  readingTypeId: number
  sortOrder?: number | null
}

/**
 * Dynamically generated bills with hansard debates page
 * @returns ProgressOfBills component
 */
export function HansardBills() {
  const [searchParams] = useSearchParams();
  const parliament = searchParams.get('parliament') ?? '42nd';
  const session = searchParams.get('session') ?? '4th';

  const parNumber = extractNumbersFromString(parliament);
  const sessNumber = extractNumbersFromString(session);
  const sessionId = useSessionIdByNumber(parNumber, sessNumber);

  const { timesOfDay } = useTimesOfDay();

  const { bills } = useBillsBySession(sessionId ?? 0);
  const memberIds = bills
    ?.map(bill => bill.memberId)
    ?.filter(memberId => memberId !== null) as number[] ?? [0];

  const { data } = useQuery(GET_BILLS_MEMBERS, { variables: { in: memberIds } });
  const memberMap = groupBy(data?.allMembers?.nodes ?? [], (member) => member.id)

  const govBills = bills?.filter(bill => bill.billTypeId === 1);
  const memBills = bills?.filter(bill => bill.billTypeId === 2);
  const prBills = bills?.filter(bill => bill.billTypeId === 3);
  const totalCount = bills?.length ?? 0;

  const [debates, setDebates] = useState<HansardDebateWithBill[]>();
  const debatesById = groupBy(debates ?? [], (debate) => debate?.id);

  const billTranscriptLinks = debates?.flatMap(debate => debate?.billTranscriptLinksByFileId?.nodes ?? []) ?? [];
  const trancriptLinksByBill = groupBy(billTranscriptLinks, (link) => link?.billId ?? 0);

  useEffect(() => {
    const loadDebates = async () => {
      const response = await get<HansardDebateWithBill[]>(`${getAPIBaseUrl()}/hdms/bills/${sessionId}`);
      setDebates(response);
    }
    if (sessionId) {
      void loadDebates();
    }
  }, [sessionId]);

  const [selectedBill, setSelectedBill] = useState(0);

  const handleBillTypeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedBill(Number(event.target.value));
  };

  const parliaments = useAllParliaments();
  const sessionObj = parliaments?.find(parl => parl?.number === parNumber)
    ?.sessionsByParliamentId?.nodes?.find(sess => sess.number === sessNumber);
  const startYear = sessionObj?.startDate?.length
    ? new Date(sessionObj?.startDate ?? '')?.getFullYear().toString()
    : '';

  const periodTitle = `${session} Session, ${parliament} Parliament${startYear?.length ? ` (${startYear})` : ''}`;
  const progressOfBillsLink = `${getParentOrigin()}/parliamentary-business/overview/${parliament}-parliament/${session}-session/bills/progress-of-bills`;

  return (
    <div className={style.hansardBills}>
      <div className={style.hbDisclaimer}>
        <p>{periodTitle}</p>

        <div>
          <span>
            <a href={progressOfBillsLink} target='_parent'>
              Progress of Bills for the {periodTitle}
            </a>
          </span>
        </div>
      </div>

      <div className={style.hbHeader}>
        {totalCount
        ? <>
          <h1>Bills with Hansard debates</h1>
          <select value={selectedBill} onChange={handleBillTypeSelect} title='Bill Types'>
            <option value={0}>All Bill Types</option>
            <option value={1}>Government Bills</option>
            <option value={2}>Members&apos; Bills</option>
            <option value={3}>Private Bills</option>
          </select>
        </>
        : <h1>No bills have been tabled for this session.</h1>}
      </div>

      {govBills?.length && (selectedBill === 0 || selectedBill === 1)
        ? <div className={style.hbBillGroup}>
          <h2>Government Bills</h2>

          <div className={style.hbBillGrid}>
            {govBills.map((bill, index) => {
              const billLinks = trancriptLinksByBill.get(bill?.id ?? 0);
              const debateIds = Array.from(new Set(billLinks?.map(link => link?.fileId) ?? []));
              const billDebates = debateIds.map(id => debatesById.get(id)?.[0])
                .filter(debate => debate !== undefined) as HansardDebateWithBill[];
              return (
                <DebateBillCard
                  key={bill.id}
                  bill={bill}
                  debates={billDebates}
                  member={memberMap.get(bill?.memberId ?? 0)?.[0] as (Member | undefined)}
                  parliamentString={parliament}
                  sessionString={session}
                  timesOfDay={timesOfDay}
                />
              );
            })}
          </div>
        </div>
      : null}

      {memBills?.length && (selectedBill === 0 || selectedBill === 2)
        ? <div className={style.hbBillGroup}>
          <h2>Members&apos; Bills</h2>

          <div className={style.hbBillGrid}>
            {memBills.map((bill, index) => {
              const billLinks = trancriptLinksByBill.get(bill?.id ?? 0);
              const debateIds = Array.from(new Set(billLinks?.map(link => link?.fileId) ?? []));
              const billDebates = debateIds.map(id => debatesById.get(id)?.[0])
                .filter(debate => debate !== undefined) as HansardDebateWithBill[];
              return (
                <DebateBillCard
                  key={bill.id}
                  bill={bill}
                  debates={billDebates}
                  member={memberMap.get(bill?.memberId ?? 0)?.[0] as (Member | undefined)}
                  parliamentString={parliament}
                  sessionString={session}
                  timesOfDay={timesOfDay}
                  billPrefix='M'
                />
              );
            })}
          </div>
        </div>
      : null}

      {prBills?.length && (selectedBill === 0 || selectedBill === 3)
        ? <div className={style.hbBillGroup}>
          <h2>Private Bills</h2>

          <div className={style.hbBillGrid}>
            {prBills.map((bill, index) => {
              const billLinks = trancriptLinksByBill.get(bill?.id ?? 0);
              const debateIds = Array.from(new Set(billLinks?.map(link => link?.fileId) ?? []));
              const billDebates = debateIds.map(id => debatesById.get(id)?.[0])
                .filter(debate => debate !== undefined) as HansardDebateWithBill[];
              return (
                <DebateBillCard
                  key={bill.id}
                  bill={bill}
                  debates={billDebates}
                  member={memberMap.get(bill?.memberId ?? 0)?.[0] as (Member | undefined)}
                  parliamentString={parliament}
                  sessionString={session}
                  timesOfDay={timesOfDay}
                  billPrefix='Pr'
                />
              );
            })}
          </div>
        </div>
      : null}
    </div>
  );
}
