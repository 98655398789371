import { useState, useEffect } from 'react';
import { getAPIBaseUrl } from '../util/api';
import { get } from './api';
import { type CommitteeMembersObj } from '../components/pages/committee-about/committee-interfaces';
import { getSessionParams } from '../util/util';

export const useCommitteePeriodMembers = (parliament: string, abbreviation: string, session?: string) => {
  const [committeePeriodMembers, setCommitteePeriodMembers] = useState<CommitteeMembersObj | undefined>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadCommitteePeriodMembers = async () => {
      const params = getSessionParams(session);
      const committeePeriodMembers = await get<CommitteeMembersObj>(`${getAPIBaseUrl()}/pcms/committees/${parliament}/${abbreviation}/members`, params);
      setCommitteePeriodMembers(committeePeriodMembers);
      setLoading(false);
    };
    void loadCommitteePeriodMembers();
  }, [parliament, session, abbreviation]);

  return { committeePeriodMembers, loading };
};
