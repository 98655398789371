import React from 'react'
import { useQuery } from '@apollo/client';
import { gql } from '../../../__generated__';
import { MLAPuck } from '../find-my-mla/mla-puck';
import { Loader } from '../../common/loading/loading';
import style from './mla-by-constituency.module.css';
import { useCurrentParliament } from '../../../api/parliament';

export const GET_MLAS_BY_CONSTITUENCY = gql(`
query GetMLAsByConstituency($parliamentId: Int!) {
  allMemberParliaments(
    condition: { parliamentId: $parliamentId, active: true }
  ) {
    nodes {
      image: imageBySmallImageId {
        path
        description
      }
      constituency: constituencyByConstituencyId {
        name
      }
      member: memberByMemberId {
        firstName
        lastName
      }
      isCounsel
      isDoctor
      isHonourable
      party: partyByPartyId {
        name
        abbreviation
      }
      nodeId
    }
  }
}
`);

/**
 * The find mla by constituency page
 * @returns React Element for the main Find mla by constituency
 */
export function MLAByConstituency() {
  const { currentParliament } = useCurrentParliament();
  const { loading, data } = useQuery(GET_MLAS_BY_CONSTITUENCY,
    { variables: { parliamentId: currentParliament?.id ?? 0 } });

  const mlaData = [...(data?.allMemberParliaments?.nodes ?? [])];
  mlaData.sort((a, b) => {
    const nameA = a?.constituency?.name ?? '';
    const nameB = b?.constituency?.name ?? '';
    return nameA.localeCompare(nameB);
  });
  return (
    <Loader loading={loading} notFound={!mlaData?.length}>
      <div className={style.mlaByConstituencyTitle}>
        <span>Constituency</span>
        <span>MLA</span>
        <span>Party</span>
      </div>
      <div className={style.mlaByConstituency}>
        {mlaData?.map((memberParl) => {
          const member = memberParl?.member;
          return (
            <React.Fragment key={memberParl?.nodeId}>
              <div>{memberParl?.constituency?.name}</div>
              <div>
                <MLAPuck
                  firstname={member?.firstName}
                  lastname={member?.lastName}
                  isHonourable={memberParl?.isHonourable}
                  isCounsel={memberParl?.isCounsel}
                  image={memberParl?.image}
                  style={style.mlaPuck}
                  parliament={currentParliament}
                />
              </div>
              <div>{memberParl?.party?.abbreviation}</div>
            </React.Fragment>
          );
        })}
      </div>
    </Loader>
  )
}
