import React from 'react';
import style from './committee-reports.module.css';
import { DateTimeConverter } from '../../common/date-time-converter/date-time-converter';
import { type CommitteeFileAttributeNode } from '../committee-about/committee-interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { getParentOrigin } from '../../../util/api';
import { getFileExtension } from '../../../util/util';

export interface ReportCardProp {
  cardObj: CommitteeFileAttributeNode
  cardStyle: string
}

/**
 * Report card for displaying committee report
 * @param props react properties
 */
export function ReportCard(props: ReportCardProp) {
  const baseObj = props.cardObj;
  const fileInfo = baseObj.supportingFileAttributesByFileId?.nodes[0];
  const commName = fileInfo?.committeePeriodByCommitteePeriodId?.name;
  const reportDate = baseObj.date;
  const filePath = baseObj?.filePath;
  const fileName = baseObj?.fileName;
  const fileTitle = baseObj?.title;
  const docUrl = `${getParentOrigin()}/committee-content${filePath}/${fileName}`;
  const fileExtension = getFileExtension(fileName ?? '');
  return (
    <div>
      <div className={`${style.card} ${props.cardStyle}`}>
        {commName
          ? <div className={`${style.light} ${style.section}`}>{commName}</div>
          : <></>
        }
        <div className={style.cardHeader}>
          <div className={style.section}>
            <a href={docUrl} target='_parent'>{<div className={style.title}>{fileTitle}</div>}</a>
          </div>
          <div>
            {reportDate
              ? <div className={`${style.section} ${style.box}`}>
                <span className={style.mark}>(</span>
                <DateTimeConverter dateString={reportDate}></DateTimeConverter>
                <span className={style.mark}>)</span>
                </div>
              : <></>
            }
          </div>
          <div className={style.section}>
            <a href={docUrl} target='_parent'>
              <FontAwesomeIcon icon={fileExtension === 'PDF' ? faFilePdf : faFile} className={style.iconStyle}/>
              {fileExtension}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
