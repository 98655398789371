import { useQuery } from '@apollo/client';
import { gql } from '../../../__generated__';

/**
 * graphql query to load the amount of members per party and the total number of constituencies
 */
export const GET_EXECUTIVE_COUNCIL = gql(`
  query GetExecutiveCouncil($parliament: Int!) {
    allMemberParliaments(
      condition: {parliamentId: $parliament}
      filter: {memberRolesByMemberParliamentId: {some: {roleByRoleId: {typeId: {in: [1]}}}}}
    ) {
      nodes {
        id
        memberByMemberId {
          firstName
          lastName
        }
        memberRolesByMemberParliamentId(
          condition: {active: true}
           filter: {roleByRoleId: {typeId: {in: 1}}}
         ) {
          nodes {
            roleId
            active
            endDate
            roleByRoleId {
              title
              typeId
            }
          }
        }
        isHonourable
        isCounsel
      }
    }
  }
`);

/**
 * gets the executive council for a parliament
 * @param parliamentId id of the parliament to load
 * @returns query results for getting the executive council
 */
export function useExecutiveCouncil(parliamentId: number | undefined | null) {
  return useQuery(GET_EXECUTIVE_COUNCIL, { variables: { parliament: parliamentId ?? 0 } });
}
