import React from 'react';
import style from './tab.module.css';
export interface TabProps {
  children: React.ReactNode
  onClick?: () => void
  selected?: boolean
}

/**
 * Header for the BC Legislature
 * @param props react props
 */
export function Tab(props: TabProps) {
  const click = () => {
    if (props.onClick) {
      props.onClick();
    }
  }
  return (
    <div className={`${style.tab} ${props.selected ? style.selected : ''}`} onClick={click}>
      {props.children}
    </div>
  )
}
