import React from 'react';
import style from './header.module.css';

interface HeaderProps {
  children: React.ReactNode
  className?: string
  variant?: 'none' | 'thin'
  size?: 'md' | 'sm'
}

/**
 * Header for the BC Legislature
 * @param props react props
 */
export function Header(props: HeaderProps) {
  return (
    <h2 className={`${style.header} ${props.variant === 'thin' ? style.thin : ''} ${props.size === 'sm' ? style.small : ''} ${props.className ? props.className : ''}`}>
      {props.children}
    </h2>
  )
}
