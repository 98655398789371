import React from 'react';
import { StringContentRender } from '../string-content-render/string-content-render';

export interface CommInfo {
  commString: string | undefined
  title: string
}

/**
 * @param prop holds committee information
 * @returns component with a string paragraph about a committee
 */
export function CommitteeParagraph(prop: CommInfo) {
  return (
    <div>
      {prop.commString
      ? <div>
        <h2>{prop.title}</h2>
        <StringContentRender content={prop.commString}></StringContentRender>
      </div>
      : <></>}
    </div>
  );
}
