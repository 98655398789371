import React from 'react';
import style from '../../pages/committee-about/committee-about.module.css';
import { MLAPuck } from '../../pages/find-my-mla/mla-puck';
import { type CommitteeClerks, type CommitteeMember, type CommitteeMembersObj } from '../../pages/committee-about/committee-interfaces';

interface CommMemberInfo {
  commMemberObj: CommitteeMembersObj | undefined
  commMemberLength: number
  title: string
  parliament: string
}

/**
 * @param props holds committee information
 * @returns component with a string paragraph about a committee
 */
export function CommitteeMembers(props: CommMemberInfo) {
  let chairData: CommitteeMember | undefined;
  let chairDeputyData: CommitteeMember | undefined;
  let convenerData: CommitteeMember | undefined;
  let commClerkObj: CommitteeClerks | undefined = { nodes: [] };
  const commMember = props.commMemberLength;

  if (props.commMemberObj) {
    chairData = props.commMemberObj?.committeeChair;
    chairDeputyData = props.commMemberObj?.committeeDeputyChair;
    convenerData = props.commMemberObj?.committeeConvener;
    commClerkObj = props.commMemberObj?.committeeClerks;
    props.commMemberObj?.committeeMembers?.allMemberParliaments?.nodes?.sort((a, b) => {
      const aName = a.memberByMemberId.lastName;
      const bName = b.memberByMemberId.lastName;
      return aName.localeCompare(bName);
    })
  }
  return (
    <div>
      {commMember !== 0
      ? <div>
          <h2>{props.title}</h2>
          <ul className={style.memberList}>
            {chairData !== undefined && commMember !== 0
            ? <MLAPuck firstname={chairData?.memberByMemberId.firstName} lastname={chairData?.memberByMemberId.lastName} key={1} image={chairData?.imageBySmallImageId} isHonourable={chairData?.isHonourable} isCounsel={chairData?.isCounsel} title='(Chair)' titleStyle={style.memberTitle} style={style.memberPuck} parliamentString={props.parliament}></MLAPuck>
            : <></>}
            {chairDeputyData !== undefined && commMember !== 0
            ? <MLAPuck firstname={chairDeputyData?.memberByMemberId.firstName} lastname={chairDeputyData?.memberByMemberId.lastName} key={2} image={chairDeputyData?.imageBySmallImageId} isHonourable={chairDeputyData?.isHonourable} isCounsel={chairDeputyData?.isCounsel} title='(Deputy Chair)' titleStyle={style.memberTitle} style={style.memberPuck} parliamentString={props.parliament}></MLAPuck>
            : <></>}
            {convenerData !== undefined && commMember !== 0
            ? <MLAPuck firstname={convenerData?.memberByMemberId.firstName} lastname={convenerData?.memberByMemberId.lastName} key={3} image={convenerData?.imageBySmallImageId} isHonourable={convenerData?.isHonourable} isCounsel={convenerData?.isCounsel} title='(Convener)' titleStyle={style.memberTitle} style={style.memberPuck} parliamentString={props.parliament}></MLAPuck>
            : <></>}
            {props.commMemberObj?.committeeMembers?.allMemberParliaments?.nodes.map((member, index) => {
              return (
                <MLAPuck firstname={member?.memberByMemberId.firstName} lastname={member?.memberByMemberId.lastName} key={index + 3} image={member?.imageBySmallImageId} isHonourable={member?.isHonourable} isCounsel={member?.isCounsel} style={style.memberPuck} parliamentString={props.parliament}></MLAPuck>
              )
            })}
          </ul>
          {commClerkObj !== undefined && commClerkObj.nodes.length > 0 && commMember !== 0
            ? <div className={style.committeeClerk}>
                <h3>{`${commClerkObj.nodes.length > 1 ? 'Clerks to the Committee' : 'Clerk to the Committee'}`}</h3>
                <ul className={style.memberList}>
                  {commClerkObj.nodes.map((clerk, index) => {
                    return (
                      <div className={style.clerkInfo} key={index}>
                        <div className={style.memberTitle} key={index}>{clerk.firstName} {clerk.lastName}</div>
                        <div className={style.memberTitle}>{clerk.name}</div>
                      </div>
                    )
                  })}
                </ul>
              </div>
            : <></>}
          </div>
      : <></>}
    </div>
  );
}
