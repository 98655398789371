/**
 * performs a get request
 * @param url url to get
 * @param queryParams query parameters
 */
export async function get<T = any>(url: string, queryParams?: Record<string, any>) {
  if (queryParams) {
    const queryArray = Object.entries(queryParams);
    const searchParams = new URLSearchParams(queryArray);
    if (searchParams.size) {
      url += `?${searchParams.toString()}`
    }
  }
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await response.json() as T;
}
