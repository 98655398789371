import React from 'react';
import style from './table-row.module.css';
import { DropdownItem } from '../dropdown-list/dropdown-item';
import { type PeriodCommitteeNode } from '../../pages/previous-committees/previous-committees';

interface SessionProps {
  session: PeriodCommitteeNode[]
  parliament: string
  committee: string
}

/**
 * Table row for displaying data based on it's position
 * @param props react properties
 * @returns list of sessions
 */
export function TableRow(props: SessionProps) {
  const getTextByPos = (sessions: PeriodCommitteeNode[], pos: number): { text: string, url: string } => {
    const session = sessions.find((s) => s.number === pos);
    if (session) {
      const sessionName = `${session.number}${session.annotation} Session`;
      const sessionId = `${session.number}${session.annotation}session`;
      const sessionURL = `${props.parliament}-${sessionId}-${props.committee}`;
      return {
        text: sessionName,
        url: sessionURL
      };
    }
    return { text: '', url: '' };
  };

  const renderCellContent = (pos: number) => {
    const { text, url } = getTextByPos(props.session, pos);

    return (
      <div>
        { text !== ''
        ? <DropdownItem title={text} url={url}></DropdownItem>
        : <></>
        }
      </div>
    );
  };

  return (
    <div className={style.table}>
      <div className={style.row}>
        <div className={`${style.cell} ${style.notEmpty}`}>{renderCellContent(1)}</div>
        {[2, 3, 4, 5, 6].map((num, index) => (
          <div
            className={`${style.cell} 
                        ${getTextByPos(props.session, num).text ? style.withBorder : style.noBorder}
                      `}
            key={index}
          >
            {renderCellContent(num)}
          </div>
        ))}
      </div>
    </div>
  );
}
