import React, { cloneElement, useState } from 'react';
import { type TabProps } from '../tab/tab';
import style from './tabs.module.css';

interface TabsProps {
  children: Array<React.ReactElement<TabProps>> | React.ReactElement<TabProps>
  onChange: (index: number) => void
}

/**
 * Header for the BC Legislature
 * @param props react props
 */
export function Tabs(props: TabsProps) {
  const [selected, setSelected] = useState(0);
  const children = Array.isArray(props.children) ? props.children : [props.children];
  return (
    <div className={style.tabs}>
      {children.map((child, index) => {
        return cloneElement(child, { onClick: () => { setSelected(index); props.onChange(index) }, selected: selected === index, key: index })
      })}
    </div>
  )
}
