import { useState, useEffect } from 'react';
import { getAPIBaseUrl } from '../util/api';
import { get } from './api';
import { type CommitteeTranscript, type AllCommitteeMeetings } from '../interfaces/committee-about';
import { type FileAttribute, type TranscriptAndAudioObj } from '../components/pages/committee-about/committee-interfaces';
import { getSessionParams } from '../util/util';

export interface CommitteeTranscriptQuery {
  allMeetings: { allCommitteeMeetings: AllCommitteeMeetings }
  committeeTranscripts: CommitteeTranscript[]
  committeeIndex: FileAttribute[]
  sesOrParId?: number
}

export const useCommitteeTranscripts = (stCursor: string | null, enCursor: string | null, commId?: string) => {
  const [committeeTranscripts, setCommitteeTranscripts] = useState<TranscriptAndAudioObj>();
  const [loading, setLoading] = useState(true);
  let pcmsUrl = commId ? `pcms/parliamentary-business/committees/committee-meetings/${commId}` : 'pcms/parliamentary-business/committees/committee-meetings';
  pcmsUrl += stCursor ? `?stCursor=${stCursor}` : '';
  pcmsUrl += enCursor ? `${stCursor ? '&' : '?'}enCursor=${enCursor}` : '';

  useEffect(() => {
    const loadCommitteeTranscripts = async () => {
      const prevCommitteeTranscriptsObj = await get<TranscriptAndAudioObj>(`${getAPIBaseUrl()}/${pcmsUrl}`);
      setCommitteeTranscripts(prevCommitteeTranscriptsObj);
      setLoading(false);
    };
    void loadCommitteeTranscripts();
  }, [stCursor, enCursor]);
  return { committeeTranscripts, loading };
};

export const useCommitteePeriodTranscripts = (parliament: string, abbreviation: string, session?: string) => {
  const [committeePeriodTranscripts, setCommitteePeriodTranscripts] = useState<CommitteeTranscriptQuery>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadCommitteePeriodTranscripts = async () => {
      const params = getSessionParams(session);
      const committeePeriodTranscripts = await get<CommitteeTranscriptQuery>(`${getAPIBaseUrl()}/pcms/committees/${parliament}/${abbreviation}/transcripts`, params);
      setCommitteePeriodTranscripts(committeePeriodTranscripts);
      setLoading(false);
    };
    void loadCommitteePeriodTranscripts();
  }, [parliament, session, abbreviation]);
  return { committeePeriodTranscripts, loading };
};
