import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { getParentOrigin } from '../../../util/api';
import style from './parliament-index.module.css';
import { Loader } from '../../common/loading/loading';
import { gql } from '../../../__generated__';
import { useQuery } from '@apollo/client';

export const GET_SESSIONS = gql(`
  query MyQuery($number: Int!) {
    allParliaments(condition: { number: $number, active: true }) {
      nodes {
        sessionsByParliamentId(condition: { active: true }) {
          nodes {
            number
            annotation
          }
        }
      }
    }
  }
`);

/**
 * Overview for a session of parliament
 */
export function ParliamentIndex() {
  const [searchParams] = useSearchParams();
  const parliament = searchParams.get('parliament') ?? '42nd';
  const parliamentNumber = parliament.replace(/\D/g, '');
  const { loading, data: sessionsQuery } = useQuery(GET_SESSIONS, { variables: { number: parseInt(parliamentNumber, 10) } });
  const baseUrl = `${getParentOrigin()}/parliamentary-business/overview/${parliament}-parliament`;
  const sessions = sessionsQuery?.allParliaments?.nodes?.[0].sessionsByParliamentId.nodes ?? [];
  return (
    <Loader loading={loading} notFound={!sessions?.length}>
      <div className={style.sessionOverview}>
        <div className={style.groupLinks}>
          {sessions.map((session, index) => {
            return (<div key={index}><a href={`${baseUrl}/${session.number}${session.annotation}-session`} target='_parent'>{session.number}{session.annotation} Session</a></div>);
          })}
        </div>
      </div>
    </Loader>
  )
}
