import React from 'react';
import './side-nav.css';
interface SideNavProps {
  children?: React.ReactNode
  headerText: string
  removedPadding?: boolean
  divStyle?: string
  navStyle?: string
}

/**
 * Side nav - box with a header and displayed items
 * @param props react properties
 * @param props.headerText header text to display
 * @param props.children react nodes to disaply as a list
 * @param props.removedPadding  boolean to remove padding style
 * @param props.divStyle  string to add additional style
 * @param props.navStyle  string to add additional style
 * @returns find my mla headshot puck
 */
export function SideNav({ headerText, children, removedPadding, divStyle, navStyle }: SideNavProps) {
	return (
		<div className={`side-nav ${divStyle ?? ''}`}>
      <h3 className='side-nav-header'>
        {headerText}
      </h3>
      <div className={`side-nav-content ${removedPadding ? '' : 'with-padding'} ${navStyle ?? ''}`}>
        {children}
      </div>
		</div>
	)
}
