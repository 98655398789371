import React, { type MutableRefObject, useEffect, useRef, useState } from 'react';
import { DropdownList } from '../dropdown-list/dropdown-list';
import style from '../../pages/committee-about/committee-about.module.css';
import { type Parliament, type Session } from '../../pages/committee-about/committee-interfaces';

export interface ParliamentProps {
  parliamentObj?: Parliament
  getSessionURL: (parliament: string, abbreviation: string, session?: Session | undefined) => string
  currentSession?: string
  currentParliament: string
  hideCurrentParliament?: boolean
  abbreviation: string
}

/**
 * List of nav that displays sessions items
 * @param props react properties
 * @returns find list of sessions
 */
export function SessionPuck(props: ParliamentProps) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef() as MutableRefObject<HTMLHeadingElement>;

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const toggleList = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const parliament = `${props.parliamentObj?.number}${props.parliamentObj?.annotation} Parliament`
  const parliamentURL = `${props.parliamentObj?.number}${props.parliamentObj?.annotation}parliament`
  const isCurrentParliament = `${props.parliamentObj?.number}${props.parliamentObj?.annotation}` === props.currentParliament;
  const isActive = !props.hideCurrentParliament && isCurrentParliament;
  return (
    <div className={`${style.nav} ${isOpen ? style.active : ''} ${isActive ? style.currentParliament : ''}`}>
      <span onClick={toggleList} ref={menuRef} className={style.parliamentName}>{parliament} <span className={style.caret}></span></span>
      <DropdownList abbreviation={props.abbreviation} sessions={props.parliamentObj?.sessionsByParliamentId} parliament={parliamentURL} getSessionURL={props.getSessionURL} active={isOpen} currentSession={props.currentSession} isCurrentParliament={isCurrentParliament}></DropdownList>
    </div>
  )
}
