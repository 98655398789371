import React from 'react';
import style from './button.module.css';

interface ButtonProps {
    children: React.ReactNode
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}

/**
 * BC Leg Find My MLA button with styling
 * @param props react properties
 */
export function Button(props: ButtonProps) {
	return (
		<button className={style.button} type='button' onClick={props.onClick ? props.onClick : undefined}>
            {props.children}
        </button>
	)
}
