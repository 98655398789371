import React from 'react';
import style from '../table-row/table-row.module.css';
import { type SessionNodeObj, type AllPrevCommittees } from '../../pages/previous-committees/previous-committees';
import { TableStack } from '../table-stack/table-stack';
import { TableParliamentBlock } from '../table-stack/table-parliament-block';

interface CommBlockProp {
  committee: AllPrevCommittees | undefined
  parliament: SessionNodeObj | undefined
}

/**
 * Table stack for displaying categories of committees and their table row
 * @param commObj react properties
 * @returns a block of committees based on committee type
 */
export function CommitteeBlock (commObj: CommBlockProp) {
  const parliamentInfo = commObj.parliament;
  const committeeInfo = commObj.committee;
  const parNum = parliamentInfo?.number;
  const parAnnotation = parliamentInfo?.annotation;
  const parliamentURL = `parliamentary-business/committees/${parNum}${parAnnotation}parliament`;
  const parliamentTitle = `${parNum}${parAnnotation} Parliament`;
  const standingComm = committeeInfo?.standing;
  const specialComm = committeeInfo?.special;
  const statutoryComm = committeeInfo?.statutory;
  return (
    <div className={style.commStack}>
      {committeeInfo && parliamentInfo
        ? <div>
            {standingComm
              ? <div>
                  <TableStack parliament={parliamentInfo} parliamentLink={parliamentURL} committee={standingComm} committeeType='Select Standing Committees'></TableStack>
                </div>
              : <></>}
              {specialComm
              ? <div>
                  <TableStack parliament={parliamentInfo} parliamentLink={parliamentURL} committee={specialComm} committeeType='Special Committees'></TableStack>
                </div>
              : <></>}
              {statutoryComm
              ? <div>
                  <TableParliamentBlock committee={statutoryComm?.[0]} parliamentLink={parliamentURL} parliamentText={parliamentTitle} committeeType='Statutory Committee'></TableParliamentBlock>
                </div>
              : <></>}
          </div>
        : <></>}
    </div>
  );
}
