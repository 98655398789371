import React from 'react'
import style from '../committee-about/committee-about.module.css';
import { Loader } from '../../common/loading/loading';
import { useLocation } from 'react-router-dom';
import { getCommitteeParliamentLocation2, getCommitteeSessionLocation, sendTitleToParent } from '../../../util/util';
import { CommitteeNav } from '../../common/committee-nav/committee-nav';
import { CommitteeParagraph } from '../../common/committee-paragraph/committee-paragraph';
import { CommitteeParliamentSelect } from '../../common/committee-parliament-select/committee-parliament-select';
import { useCommitteeSideNav, type CommitteeSideNav } from '../../../api/committee-nav';
import { type Session } from '../committee-about/committee-interfaces';

interface CommitteeParagraphProps {
  propertyName: keyof CommitteeSideNav
  pageTitle: string
  pageIndex: number
  isTwoColumn?: boolean
  isSession: boolean
}
/**
 * The find Parliamentary Committee Page
 * @param props page prperties
 * @returns React Element for the Parliamentary Committee Follow Up Page
 */
export function CommitteePeriodParagraph(props: CommitteeParagraphProps) {
  const location = useLocation();
  let session: string | undefined, abbreviation: string, parliament: string;
  if (props.isSession) {
    const locationData = getCommitteeSessionLocation(location);
    abbreviation = locationData.commId;
    parliament = locationData.parliament;
    session = locationData.session;
  } else {
    const locationData = getCommitteeParliamentLocation2(location);
    abbreviation = locationData.commId;
    parliament = locationData.parliament;
  }
  const { committeeNav: sideNavInfo, loading } = useCommitteeSideNav(parliament, abbreviation, session);
  const pageTitle = props.pageTitle;
  const pageIndex = props.pageIndex;
  const pageDetail = sideNavInfo?.[props.propertyName];
  const isTwoColumn = props.isTwoColumn;
  const name = sideNavInfo?.name;
  const urlString = `${parliament}parliament-${session ? `${session}session-` : ''}${abbreviation}`;

  const getSessionURL = (parliament: string, abbreviation: string, session?: Session | undefined) => {
    return `parliamentary-business/committees/${parliament}-${session ? `${session.number}${session.annotation}session-` : ''}${abbreviation}`;
  };

  if (name) {
    sendTitleToParent(name);
  }

  const breadcrumbs = [
    {
      name: 'shortName',
      order: 2
    },
    {
      name: props.pageTitle
    }]
  return (
    <Loader loading={loading} notFound={!sideNavInfo}>
      <h2>{parliament} Parliament{session ? `, ${session} Session` : ''}</h2>
      <div>
        <CommitteeParliamentSelect abbreviation={abbreviation} getSessionURL={getSessionURL} currentSession={session} currentParliament={parliament}/>
        <div className={isTwoColumn ? `${style.committeeAboutContent}` : `${style.committeeContent}`}>
          <CommitteeNav parliament={parliament} session={session} abbreviation={abbreviation} urlAppendix={urlString} activeIndex={pageIndex} breadcrumbs={breadcrumbs}/>
          <div className={style.info}>
            {pageDetail
              ? <CommitteeParagraph commString={String(pageDetail)} title={pageTitle} ></CommitteeParagraph>
              : <></>
            }
          </div>
        </div>
      </div>
    </Loader>
  )
}
