import React, { useEffect, useState } from 'react'
import { getAPIBaseUrl, getParentOrigin } from '../../../util/api';
import { useSearchParams } from 'react-router-dom';
import { FileList } from '../../common/file-list/file-list';
import { get } from '../../../api/api';

export interface OrdersOfTheDayQuery {
  allParliamentaryFileAttributes: AllParliamentaryFileAttributes
}

export interface AllParliamentaryFileAttributes {
  nodes: OrderOfTheDay[]
}

export interface OrderOfTheDay {
  fileName: string
  filePath: string
  published: boolean
  publishTime: Date
  date: Date
  title: null
  ordersAttributesByFileId: OrdersAttributesByFileID
}

export interface OrdersAttributesByFileID {
  nodes: OrdersAttributesByFileIDNode[]
}

export interface OrdersAttributesByFileIDNode {
  number: number
  timesOfDayByTodId: TimesOfDayByTodID
}

export interface TimesOfDayByTodID {
  name: string
}

/**
 * The find mla by constituency page
 * @returns React Element for the main Find mla by constituency
 */
export function OrdersOfTheDay() {
  const [searchParams] = useSearchParams();
  const parliament = searchParams.get('parliament') ?? '42nd';
  const session = searchParams.get('session') ?? '4th';

  const [orders, setOrders] = useState<OrderOfTheDay[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadItems = async () => {
      const files = await get<OrdersOfTheDayQuery>(`${getAPIBaseUrl()}/pdms/orders-of-the-day/${parliament}${session}`);
      const orders = files.allParliamentaryFileAttributes.nodes;
      orders.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB.getTime() - dateA.getTime();
      })
      setOrders(orders);
      setLoading(false);
    };

    loadItems().catch(e => {
      console.log(e);
    });
  }, []);

  const getItemLabel = (item: OrderOfTheDay) => {
    if (item.title) {
      return item.title;
    }
    const date = new Date(item.date);
    const dateString = date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    const orderAttributes = item.ordersAttributesByFileId.nodes?.[0];
    return `Orders (${dateString}, ${orderAttributes.timesOfDayByTodId.name} No. ${orderAttributes.number})`;
  }

  const getItemLink = (item: OrderOfTheDay) => {
    const link = `${getParentOrigin()}/parliamentary-business/overview/${parliament}-parliament/${session}-session/orders-of-the-day/${item.fileName}`
    return link;
  }

  return (
    <FileList<OrderOfTheDay> items={orders} loading={loading} itemNameCallback={getItemLabel} itemLinkCallback={getItemLink} />
  )
}
