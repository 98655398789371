import { useEffect, useState } from 'react';
import { get } from './api';
import { getAPIBaseUrl } from '../util/api';

export interface TimesOfDayQuery {
  allTimesOfDays: {
    nodes: TimeOfDay[]
  }
};

export interface TimeOfDay {
  id: number
  name: string
};

const useTimesOfDay = () => {
  const [timesOfDay, setTimesOfDay] = useState<TimeOfDay[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTimesOfDay = async () => {
      const timesOfDayQuery = await get<TimesOfDayQuery>(`${getAPIBaseUrl()}/hdms/times-of-day`);
      setTimesOfDay(timesOfDayQuery?.allTimesOfDays?.nodes ?? []);
      setLoading(false);
    };

    void loadTimesOfDay();
  }, []);

  return { timesOfDay, loading };
};

export default useTimesOfDay;
