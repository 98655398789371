import React from 'react'
import style from '../committee-about/committee-about.module.css';
import { Loader } from '../../common/loading/loading';
import { useLocation } from 'react-router-dom';
import { getCommitteeParliamentLocation2, getCommitteeSessionLocation } from '../../../util/util';
import { CommitteeNav } from '../../common/committee-nav/committee-nav';
import { TranscriptPagination } from '../committee-calendar/transcript-pagination';
import { CommitteeIndexList } from '../../common/committee-index-list/committee-index-list';
import { useCommitteePeriodTranscripts } from '../../../api/committee-transcripts';
import { CommitteeParliamentSelect } from '../../common/committee-parliament-select/committee-parliament-select';
import { type Session } from '../committee-about/committee-interfaces';

interface CommitteePeriodMeetingsProps {
  isSession: boolean
}

/**
 * The find Parliamentary Committee Page
 * @param props committee period meeting props
 * @returns React Element for the main Parliamentary Committee Meetings Page
 */
export function CommitteePeriodMeetings(props: CommitteePeriodMeetingsProps) {
  const location = useLocation();
  let session: string | undefined, abbreviation: string, parliament: string;
  if (props.isSession) {
    const locationData = getCommitteeSessionLocation(location);
    abbreviation = locationData.commId;
    parliament = locationData.parliament;
    session = locationData.session;
  } else {
    const locationData = getCommitteeParliamentLocation2(location);
    abbreviation = locationData.commId;
    parliament = locationData.parliament;
  }
  const { committeePeriodTranscripts, loading } = useCommitteePeriodTranscripts(parliament, abbreviation, session);
  const commMeetings = committeePeriodTranscripts;
  const urlString = `${parliament}parliament-${session ? `${session}session-` : ''}${abbreviation}`;

  const getSessionURL = (parliament: string, abbreviation: string, session?: Session | undefined) => {
    return `parliamentary-business/committees/${parliament}-${session ? `${session.number}${session.annotation}session-` : ''}${abbreviation}`;
  };

  const indexes = commMeetings?.committeeIndex;

  const breadcrumbs = [
    {
      name: 'shortName',
      order: 2
    },
    {
      name: 'Meetings'
    }]
  return (
    <Loader loading={loading} notFound={!committeePeriodTranscripts}>
      <h2>{parliament} Parliament{session ? `, ${session} Session` : ''}</h2>
      <div>
        <CommitteeParliamentSelect abbreviation={abbreviation} getSessionURL={getSessionURL} currentSession={session} currentParliament={parliament}/>
        <div>
            {indexes
              ? <CommitteeIndexList committeeIndexes={indexes}></CommitteeIndexList>
              : <></>
            }
        </div>
        <div className={style.committeeAboutContent}>
          <CommitteeNav parliament={parliament} session={session} abbreviation={abbreviation} urlAppendix={urlString} activeIndex={4} breadcrumbs={breadcrumbs} />
          <div className={style.info}>
            {commMeetings
              ? <div>
                  <h2>Meetings</h2>
                  <TranscriptPagination baseUrl={urlString} transcripts={commMeetings} ></TranscriptPagination>
                </div>
              : <></>
            }
          </div>
        </div>
      </div>
    </Loader>
  )
}
