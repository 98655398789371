import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import style from './table-list.module.css';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
interface ListItem {
  text: string
  url: string
  icon?: IconProp
  active?: boolean
  classes?: string[]
}

export interface DynamicListProps {
  items: ListItem[]
  committeeStyle?: boolean
  addIcon?: boolean
}

/**
 * @param props holds the list of items the table
 * @returns find list of items about a committee
 */
export function TableList(props: DynamicListProps) {
  return (
    <div>
      <ul className={`${props.committeeStyle ? style.committeeItem : style.listItem}`}>
        {props.items.map((item, index) => (
          <li key={index}>
            <a className={`${item.active ? style.activeLink : style.inactiveLink} ${item.classes?.join(' ') ?? ''}`}
              href={item.url} target='_parent'>
                {props.addIcon
                ? <FontAwesomeIcon className={style.icon} icon={item?.icon ?? faCircle} />
                : <></>} {item.text}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}
