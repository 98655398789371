import React from 'react';
import style from '../table-row/table-row.module.css';
import { type SessionNodeObj, type CommitteeNodeObj } from '../../pages/previous-committees/previous-committees';
import { TableSessionBlock } from './table-session-block';

export interface CommStackProp {
  committee: CommitteeNodeObj[] | undefined
  parliament: SessionNodeObj | undefined
  committeeType: string
  parliamentLink: string
}

/**
 * Table stack for displaying committee name and its table row
 * @param commObj react properties
 * @returns a table stack
 */
export function TableStack(commObj: CommStackProp) {
  const parliamentInfo = commObj.parliament;
  const committeeInfo = commObj.committee ? commObj.committee.sort((a, b) => a.shortName.localeCompare(b.shortName)) : undefined;
  const parNum = parliamentInfo?.number;
  const parAnnotation = parliamentInfo?.annotation;
  const parliamentUrl = commObj.parliamentLink;
  return (
    <div className={style.commStack}>
      {committeeInfo && parliamentInfo
        ? <div>
            <h3 className={style.commType}>{commObj.committeeType}</h3>
            {committeeInfo.map((period, index) => {
              return (
                <TableSessionBlock parliament={parliamentUrl} committee={period} parliamentNumber={parNum} annotation={parAnnotation} key={index}></TableSessionBlock>
              )
            })}
          </div>
        : <></>}
    </div>
  );
}
