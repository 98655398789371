import React, { useEffect, useState } from 'react';
import { Loader } from '../../common/loading/loading';
import { Tabs } from '../../common/tabs/tabs';
import { Tab } from '../../common/tab/tab';
import { TabPanel } from '../../common/tab-panel/tab-panel';
import { get } from '../../../api/api';
import { getAPIBaseUrl, getParentOrigin } from '../../../util/api';
import { useSearchParams } from 'react-router-dom';
import { Header } from '../../common';
import { FileList } from '../../common/file-list/file-list';
import { sendBreadcrumbToParent } from '../../../util/util';

export interface BillsFileQuery {
  allParliamentaryFileAttributes: AllParliamentaryFileAttributes
}

export interface AllParliamentaryFileAttributes {
  nodes: BillFile[]
}

export interface BillFile {
  fileName: string
  filePath: string
  billAttributesByFileId: BillAttributesByFileID
  published: boolean
  publishTime: string
  date: string
  title: string | null
}

export interface BillAttributesByFileID {
  nodes: BillAttributes[]
}

export interface BillAttributes {
  billTypeByBillTypeId: BillTypeByBillTypeID
  readingTypeByReadingTypeId: ReadingTypeByReadingTypeID
  parliamentaryBillByBillId: {
    billNumber: number
  }
}

export interface BillTypeByBillTypeID {
  name: string
}

export interface ReadingTypeByReadingTypeID {
  name: string
  id: number
}

/**
 * List of bills from a reading
 */
export function BillReading() {
  const [activePanel, setActivePanel] = useState(0);
  const [bills, setBills] = useState<BillFile[]>();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const parliament = searchParams.get('parliament') ?? '42nd';
  const session = searchParams.get('session') ?? '4th';
  const reading = searchParams.get('reading') ?? '1st_read';

  const abbreviationMap: Record<string, string> = {
    Government: '',
    // Keep the space after abbreviation for better stirng formatting
    Member: 'M ',
    Private: 'Pr '
  }

  useEffect(() => {
    const loadItems = async () => {
      const files = await get<BillsFileQuery>(`${getAPIBaseUrl()}/pdms/bills/${parliament}${session}/${reading}`);
      const bills = files.allParliamentaryFileAttributes.nodes;
      bills.sort((a, b) => {
        const titleA = a.title ?? '';
        const titleB = b.title ?? '';
        return titleA.localeCompare(titleB);
      })
      setBills(bills);
      setLoading(false);
    };

    loadItems().catch(e => {
      console.log(e);
    });
  }, []);

  const billsByNumber = [...(bills ?? [])];
  billsByNumber.sort((a, b) => {
    const numberA = a.billAttributesByFileId.nodes?.[0].parliamentaryBillByBillId.billNumber ?? 0;
    const numberb = b.billAttributesByFileId.nodes?.[0].parliamentaryBillByBillId.billNumber ?? 0;
    return numberA - numberb;
  });

  const filterByBillType = (arr: BillFile[], key: string) => {
    return arr.filter((bill) => {
      const attrs = bill.billAttributesByFileId.nodes?.[0];
      return attrs.billTypeByBillTypeId.name === key;
    });
  }

  const govBillsByName = filterByBillType(bills ?? [], 'Government');
  const govBillsByNum = filterByBillType(billsByNumber, 'Government');
  const memberBills = filterByBillType(bills ?? [], 'Member');
  const memberBillsByNum = filterByBillType(billsByNumber, 'Member');
  const privateBills = filterByBillType(bills ?? [], 'Private');
  const privateBillsByNum = filterByBillType(billsByNumber, 'Private');

  const getItemLabel = (item: BillFile) => {
    const typeName = item.billAttributesByFileId.nodes?.[0].billTypeByBillTypeId.name;
    const abbr = abbreviationMap[typeName] ?? '';
    return `${item.title} (Bill ${abbr}${item.billAttributesByFileId.nodes?.[0].parliamentaryBillByBillId.billNumber})` ?? `Bill ${abbr}${item.billAttributesByFileId.nodes?.[0].parliamentaryBillByBillId.billNumber}`;
  }

  const getItemLink = (item: BillFile) => {
    const filename = item.fileName;
    const link = `${getParentOrigin()}/parliamentary-business/overview/${parliament}-parliament/${session}-session/bills/${reading}/${filename}`
    return link;
  }

  const getReadingType = (readingStr: string) => {
    const readingTypes: Record<string, string> = {
      '3rd_read': 'Third Reading', // '3rd_read' needs quotes because it contains a special character
      amend: 'Amended'
    };

    return readingTypes[readingStr] || 'First Reading';
  };

  const readingTitle = getReadingType(reading);

  sendBreadcrumbToParent(readingTitle);

  const getItemLabelNumber = (item: BillFile) => {
    const typeName = item.billAttributesByFileId.nodes?.[0].billTypeByBillTypeId.name;
    const abbr = abbreviationMap[typeName] ?? '';
    return `Bill ${abbr}${item.billAttributesByFileId.nodes?.[0].parliamentaryBillByBillId.billNumber}: ${item.title}` ?? `Bill ${abbr}${item.billAttributesByFileId.nodes?.[0].parliamentaryBillByBillId.billNumber}`;
  }

  return (
    <Loader loading={loading} notFound={!bills?.length}>
      <div>
        <Tabs onChange={setActivePanel}>
          <Tab>Bills By Name</Tab>
          <Tab>Bills By Number</Tab>
        </Tabs>
        <TabPanel activeIndex={0} currentIndex={activePanel}>
          {govBillsByName?.length
          ? <>
            <Header>Government</Header>
            <FileList<BillFile> items={govBillsByName} itemNameCallback={getItemLabel} itemLinkCallback={getItemLink} />
          </> : null}
          {memberBills?.length
          ? <>
            <Header>Member</Header>
            <FileList<BillFile> items={memberBills} itemNameCallback={getItemLabel} itemLinkCallback={getItemLink} />
          </> : null}
          {privateBills?.length
          ? <>
            <Header>Private</Header>
            <FileList<BillFile> items={privateBills} itemNameCallback={getItemLabel} itemLinkCallback={getItemLink} />
          </> : null}
        </TabPanel>
        <TabPanel activeIndex={1} currentIndex={activePanel}>
          {govBillsByName?.length
          ? <>
            <Header>Government</Header>
            <FileList<BillFile> items={govBillsByNum} itemNameCallback={getItemLabelNumber} itemLinkCallback={getItemLink} />
          </> : null}
          {memberBills?.length
          ? <>
            <Header>Member</Header>
            <FileList<BillFile> items={memberBillsByNum} itemNameCallback={getItemLabelNumber} itemLinkCallback={getItemLink} />
          </> : null}
          {privateBills?.length
          ? <>
            <Header>Private</Header>
            <FileList<BillFile> items={privateBillsByNum} itemNameCallback={getItemLabelNumber} itemLinkCallback={getItemLink} />
          </> : null}
        </TabPanel>
      </div>
    </Loader>
  )
}
